import React, { useContext } from "react";
import "../../style/BottomNav.scss";
import { Link } from "react-router-dom";
import {
  HomeActiveIcon,
  HomeNavIcon,
  MeuSearchNavIcon,
  MeuSearchActiveNavIcon,
  MenuSquareIcon,
  GradientSquareIcon,
  MusicOffIcon,
  MusicOnIcon,
  WorldNewsIcon,
  ProductActiveIcon,
  WorldNewsActiveIcon,
  ProductIcon
} from "../../components/common/Icon";
import { strings, translations } from "../../services/localization";
import { isIOS, isMobile, isMobileSafari } from "react-device-detect";
import { audioContext } from "../../context/audioContext";

function BottomNav({
  additionalBottom,
  hideBottomNav,
  homeActive,
  gamesActive,
  newsActive,
  squareActive,
  accountActive,
  pageid,
  isPh
}) {

  const bottomNav = translations.bottomNav;
  
  const { isMuted, handleMusicOnOff } = useContext(audioContext);

  return (
    <div>
      <div style={{ zIndex: 40 }} className={`card d-block d-lg-none position-fixed bottom-card ${(isMobileSafari || (isMobile && isIOS)) ? 'isMobileSafari' : ''}`}>
        <div className={(hideBottomNav || !additionalBottom) ? "card-body" : "card-body"}>
          {additionalBottom && (
            <div className="pb-2 pt-3">{additionalBottom}</div>
          )}
          {
            pageid === 'Home'
              ?
              !isMuted
                ?
                <MusicOnIcon className="float-end me-3 mb-2" onClick={() => handleMusicOnOff(false)} />
                :
                <MusicOffIcon className="float-end me-3 mb-2" onClick={() => handleMusicOnOff(true)} />
              :
              <></>
          }
          {
            !hideBottomNav &&
            <div className="d-flex bottom-nav">
              <BottomNavLink
                link="/"
                checkActive={homeActive}
                icon={homeActive ? <HomeActiveIcon /> : <HomeNavIcon />}
                navLabel={strings.formatString(bottomNav.home)}
              />
              <BottomNavLink link="/news"
                checkActive={newsActive}
                icon={newsActive ? <WorldNewsActiveIcon /> : <WorldNewsIcon className="product-inactive-icon" />}
                navLabel={strings.formatString(bottomNav.news)}
              />
               <BottomNavLink link="/products"
                checkActive={gamesActive}
                icon={gamesActive ? <ProductActiveIcon className="side-product-icon-lg" />: <ProductIcon className="side-product-icon-lg product-inactive-icon" />}
                navLabel={strings.formatString(bottomNav.games)}
              />
              <BottomNavLink link="/squares"
                checkActive={squareActive}
                icon={squareActive ? <GradientSquareIcon /> : <MenuSquareIcon className="square-inactive-icon" />}
                navLabel={strings.formatString(bottomNav.square)}
              />
              <BottomNavLink
                link="/menu"
                checkActive={accountActive}
                icon={accountActive ? <MeuSearchActiveNavIcon /> : <MeuSearchNavIcon />}
                navLabel={strings.formatString(bottomNav.menu)}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default BottomNav;


export const BottomNavLink = (props) => {
  const item = props;
  return (
    <div className="py-2">
      <Link to={item.link} className={"d-flex flex-column align-items-center"}>
        <div style={{ width: '37px', height: '26px' }}>
          {item.icon}
        </div>
        <div className={item.checkActive ? 'active' : ''}>
          <span>{item.navLabel}</span>
        </div>
      </Link>
    </div>
  )
}
