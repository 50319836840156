import { useEffect, useState, useMemo } from 'react';

export function useScrollDirection({ wrapper }) {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let prevScrollY;
    const wrapperElement = document.getElementById("wrapper");
    if (wrapper) {
      prevScrollY = wrapperElement?.scrollTop ?? +prevScrollY;
    } else {
      prevScrollY = window.scrollY;
    }

    const handleScroll = () => {
      console.log('scroll')
      const currentScrollY = (wrapper) ? wrapperElement?.scrollTop ?? 0 : window.scrollY;

      if (currentScrollY > prevScrollY) {
        setScrollDirection('down');
      } else if (currentScrollY < prevScrollY) {
        setScrollDirection('up');
      }

      prevScrollY = currentScrollY;
    };

    const container = (wrapper && wrapperElement) ? wrapperElement : window;

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const memoizedScrollDirection = useMemo(() => scrollDirection, [scrollDirection]);

  return memoizedScrollDirection;
}
