import { useContext, useEffect, useRef, useState } from "react";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { translations } from "../../services/localization";
import { OnLoading } from "../../components/common/OnLoading";
import { PersonaAndArcanaIcon, FAQIcon, GettingStartedIcon, GlossaryIcon, GoToTopIcon, CloseIcon, SearchThinIcon, DropdownIcon, LeftArrowIcon, RightArrowIcon } from "../../components/common/Icon"
import { useMediaQuery } from "react-responsive";
import { Button } from '../squares/Button';
import { appContext } from "../../context/appContext";
import { FAQ, withTryCatch } from "./FAQ";
import { getData } from "../../services/fetch";
import ReactStyleBlocker from "../../components/common/ReactStyleBlocker";
import { parseToExecutableHTML } from "../../components/common/commonFnc";
import { isValidUrl } from "../squares/SquareDetail";
import IndianaDragScroll from 'react-indiana-drag-scroll';

const GuideAndFAQ = () => {
    const isPC = useMediaQuery({ minWidth: 992 });
    const { language } = useContext(appContext);

    const [selectedTab, setSelectedTab] = useState(isPC ? 0 : undefined);

    const content = translations.guideAndFaq;

    const metaTitle = "スクエア｜ANICANA θυρα（アニカナテュラー）";
    const metaDescription = "ANICANA θυρα（アニカナテュラー）の最新ニュース一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています";

    const tab = [
        {
            key: 0,
            title: content.gettingStarted,
            icon: <GettingStartedIcon sm={isPC} />,
            content: <Guide key="getting-started" />
        },
        {
            key: 1,
            title: language === 'English' ? `Acquiring and using ARCANAs/${isPC ? '' : ' '}PERSONAs` : 'アルカナ/ペルソナの取得と使用',
            props: {
                fsSmall: true,
                psSmall: true,
            },
            icon: <PersonaAndArcanaIcon />,
            content: <Guide status="acquiring" key="acquiring" />
        },
        {
            key: 2,
            title: content.glossary,
            icon: <GlossaryIcon className={isPC ? 'sm' : ''} />,
            content: <Glossary />
        },
        {
            key: 3,
            title: 'FAQ',
            icon: <FAQIcon className="lg" sm={isPC} />,
            content: isPC ? <FAQ /> : null,
            hideTab: !isPC
        },
    ]

    const tabBar = tab.map(it => (
        it.hideTab ? null : <TabButton
            key={it.key}
            active={isPC && selectedTab === it.key}
            icon={it.icon}
            onClick={() => setSelectedTab(it.key)}
            {...it.props}>
            {it.title}
        </TabButton>))

    const currentTab = tab.find(it => it.key === selectedTab);

    return (
        <AppWrapper
            title="Guide & FAQ"
            pageid="Guide-and-FAQ"
            sidebarWrapperDivClassName={"p-0"}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            noPadding isNoSpaceAround
            additionalBottom={!isPC ? (
                <div className="d-flex justify-content-end">
                    <Button
                        className="border-0 p-0"
                        onClick={() => {
                            document.getElementById('wrapper')
                                .scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                        }}>
                        <GoToTopIcon />
                    </Button>
                </div>
            ) : null}
        >
            {isPC ? null : (
                !isNaN(+selectedTab) && selectedTab !== 3 ? null : (
                    <div className="w-100">
                        <h1 className="text-center">Guide</h1>
                        <div className="divider" style={{ borderWidth: 0.5, maxWidth: isPC ? 600 : 'unset', marginBlockEnd: 20 }}></div>
                    </div>
                )
            )}
            <div className={`w-100 overflow-auto d-flex ${isPC ? 'justify-content-start mt-4' : 'flex-column px-3 my-3'}`} style={{ gap: isPC ? 35 : 20 }}>
                {isPC ? tabBar : isNaN(+selectedTab) || selectedTab === 3 ? tabBar : null}
            </div>
            <div style={{ marginBlockStart: 32, maxWidth: 1200 }}>
                {!isPC ? (currentTab?.content || <FAQ />) : currentTab?.content}
            </div>
        </AppWrapper>
    )
}

const TabButton = (props) => {
    const isPC = useMediaQuery({ minWidth: 992 });
    const { language } = useContext(appContext);
    const isEng = language === 'English';

    return (
        <Button
            className={isPC ? 'w-min-content' : 'max-w-unset w-100'}
            style={{ padding: isPC ? '15px 20px' : props.psSmall ? '20px 20px' : '20px 30px' }}
            gap={20}
            childrenClassName={`${props.active ? '' : 'text-white'} ${isEng ? 'text-wrap' : 'f-Kardust ' + (isPC ? '' : 'text-wrap')} ${isPC ? 'fs-18' : 'justify-content-start ' + (props.fsSmall ? 'fs-20' : 'fs-23')}`}
            icon={props.icon}
            active={props.active}
            onClick={props.onClick}>
            <span className={`mx-auto ${isPC ? 'fs-18' : (props.fsSmall ? 'fs-20' : 'fs-23')}`}>{props.children}</span>
        </Button>
    )
}

const Guide = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const { language } = useContext(appContext);
    const shortLang = language === "English" ? "en" : "jp";

    const fetchData = withTryCatch(async () => {
        const response = await getData(`/guides?status=${props.status || 'getting-start'}&language=${shortLang}`);
        setData(response?.data?.data);
    }, setLoading);

    useEffect(() => {
        fetchData();
    }, [])

    const isPC = useMediaQuery({ minWidth: 992 });

    return (
        <div className="position-relative" style={{ marginBlockStart: isPC ? 32 : 0 }}>
            {loading ? <OnLoading inline /> : (
                data?.map((it, index) => (<StepComponent prefix={props.status !== 'acquiring'} className={index !== 0 ? 'mt-4' : ''} key={it?.id} stepNo={index + 1} stepName={it?.step_name} guides={it?.guides} />))
            )}
        </div>
    )
}

const Glossary = () => {
    const [key, setKey] = useState('');
    const { language } = useContext(appContext);
    const shortLang = language === "English" ? "en" : "jp";

    const content = translations.faq;

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const isPC = useMediaQuery({ minWidth: 992 });

    const fetchData = withTryCatch(async (lang, keyword) => {
        const response = await getData(`/glossary?language=${lang}&keyword=${keyword ?? ''}`);
        setData(response.data.data);
    }, setLoading);

    useEffect(() => {
        fetchData(shortLang, key);
    }, [])

    return (
        <div className={isPC ? "me-3" : "mx-3"}>
            {loading ? <OnLoading /> : null}
            {isPC ? null : (<div className="w-100">
                <h1 className="text-center">Glossary</h1>
                <div className={`divider m-0 mb-5 w-100 mx-auto`} style={{ borderWidth: 0.5, maxWidth: isPC ? 600 : 'unset' }}></div>
            </div>)}
            <div className={`${isPC ? '' : 'mx-auto'} d-flex flex-center flex-column mb-4`} style={{ maxWidth: 280 }}>
                <div className="d-flex">
                    <input
                        placeholder={content.keywords}
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                        onKeyDown={async (e) => {
                            if (e.key === 'Enter') {
                                fetchData(shortLang, key);
                            }
                        }}
                        className="fs-18"
                        style={{
                            minWidth: 250,
                            color: 'white',
                            backgroundColor: 'transparent',
                            outline: 0,
                            border: 0,
                            opacity: key ? 1 : 0.5,
                        }} />

                    {key ?
                        <CloseIcon
                            className='search-icon'
                            style={{ stroke: '#BF96FF' }}
                            onClick={() => {
                                setKey('');
                                fetchData(shortLang, '');
                            }} /> :
                        <SearchThinIcon />}

                </div>
                <div className="divider w-100"></div>
            </div>
            {loading ? null : (
                data && data.length > 0 ? (
                    <table className="w-100 glossary-table" style={{ borderRadius: 10 }}>
                        {data.map(it => (
                            <tr key={it.id}>
                                <td>{it.title}</td>
                                <td>
                                    <ReactStyleBlocker>
                                        <div dangerouslySetInnerHTML={{ __html: parseToExecutableHTML(it.description) }}></div>
                                    </ReactStyleBlocker>
                                </td>
                            </tr>
                        ))}
                    </table>
                ) : <h4 className="text-center mt-5">No Result</h4>
            )}
        </div>
    )
}

const StepImage = ({ url }) => {
    const isPC = useMediaQuery({ minWidth: 992 });
    console.log(url)

    return url ? (
        <div className={isPC ? '' : 'my-3'} style={{
            width: isPC ? 189 : '100%',
            aspectRatio: '63/110',
            borderRadius: '5px',
            border: '1px solid var(--gradient2, #AB79F8)',
            background: `url(${url}) lightgray 50% / cover no-repeat`,
        }}></div>
    ) : null;
}


const StepComponent = (props) => {
    const isPC = useMediaQuery({ minWidth: 992 });
    const [opened, setOpened] = useState(false);

    const sortByOrder = (a, b) => a.order_number - b.order_number;

    return (
        <div className={isPC ? '' : 'px-1'}>
            <div className={`d-flex align-items-${isPC ? 'end me-3' : 'center mx-3'} ${props.className || ''}`} style={{ gap: 10 }} onClick={() => setOpened(prev => !prev)}>
                {props.prefix ? <h1 className={`fs-${isPC ? 22 : 18} fw-500 text-nowrap`}>STEP {props.stepNo}.</h1> : null}
                <h2 className={`fs-${isPC ? 18 : 14} fw-500`}> {props.stepName}</h2>
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ alignSelf: 'center' }}>
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            rotate: opened ? '0deg' : '-90deg',
                            transition: 'all 120ms ease-in',
                            width: '18px',
                            height: '18px'
                        }}>
                        <DropdownIcon className="dropdown-icon-sm" />
                    </div>
                </div>
            </div>
            {opened ? <div className={`divider w-100`} style={{ margin: '0 0 20px 0', borderWidth: 0.5, maxWidth: isPC ? 600 : 'unset' }}></div> : null}
            {opened ? (<ol className={isPC ? 'px-3' : 'px-4'} style={{ listStyleType: 'none', scrollbarGutter: 'stable both-edges' }}>
                {props.guides?.sort(sortByOrder)?.map((guide, index) => (
                    <li key={guide?.id} className="p-0 d-flex flex-column gap-1" style={{ lineHeight: '27px' }}>
                        <div className="d-flex gap-1">
                            <span className={`fs-${isPC ? 15 : 14} text-nowrap`}>{index + 1}.</span><span className={`fs-${isPC ? 15 : 14} text-justify`}> {guide?.title}</span>
                        </div>
                        <div className={`w-100 d-flex justify-content-center ${isPC ? '' : 'mb-4'}`}>
                            {isPC ? null : guide?.image instanceof Array ? (
                                <div className="w-100 d-flex flex-column gap-1">
                                    {guide?.image?.map(url => <StepImage url={url} />)}
                                </div>
                            ) : <StepImage url={guide?.image} />}
                        </div>
                    </li>
                ))}
            </ol>) : null}
            {isPC && opened ? <div className="px-3">
                <ImageSlider images={props.guides?.sort(sortByOrder)?.map(guide => guide?.image)} />
            </div> : null}
        </div>
    )
}

const ImageSlider = ({ images = [] }) => {
    const containerRef = useRef(null);
    const scrollAmount = 189; // image size
    const validImages = images?.flat()?.filter(isValidUrl);

    const handleScroll = (amount) => {
        const container = containerRef.current;
        container.scrollLeft = container.scrollLeft + amount;
    };

    return validImages?.length > 0 ? (
        <div className="d-flex gap-3 align-items-center">
            {validImages?.length >= 4 ? <button
                className="btn bg-transparent border-0 ps-0"
                onClick={() => handleScroll(-scrollAmount)}>
                <LeftArrowIcon />
            </button> : null}
            <IndianaDragScroll
                innerRef={containerRef}
                className="d-flex gap-4 overflow-auto"
                style={{
                    width: 'auto',
                    scrollSnapType: 'x proximity',
                    scrollBehavior: 'smooth'
                }}
            >
                {
                    validImages?.map((image, index) => (
                        <div
                            key={index}
                            style={{
                                width: 189,
                                minWidth: 189,
                                aspectRatio: '35 / 60',
                                borderRadius: '5px',
                                border: '1px solid var(--gradient2, #AB79F8)',
                                background: `url(${image}) lightgray 50% / cover no-repeat`,
                            }}></div>))
                }
            </IndianaDragScroll>
            {validImages?.length >= 4 ? <button
                className="btn bg-transparent border-0 pe-0"
                onClick={() => handleScroll(scrollAmount)}>
                <RightArrowIcon />
            </button> : null}
        </div>
    ) : null
}

export default GuideAndFAQ;