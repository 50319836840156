/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useContext, useMemo } from 'react'
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import numeral from "numeral";
import { useMediaQuery } from 'react-responsive'
import {
	ArcanaPlusIcon,
} from "../../components/common/Icon";
import { Button } from "./Button";
import { ItemCarousel } from "../../components/common/Item";
import { getToken } from "../../services/storage";
import { appContext } from "../../context/appContext";
import { translations } from '../../services/localization';
import { createMarkup } from '../../components/common/commonFnc';
import moment from 'moment';
import { ContactModal } from '../../components/common/ContactModal';
import { useIntersection } from '../../hooks/useIntersection';
import TabList from './TabList';
import TopNewsPC from "../../components/common/TopNewsPC";
import { Avatar } from './SquareProfile';
import { useSelector } from 'react-redux';
import { TokenType } from '../../components/common/enum';
import { MiniGameLink } from '../../components/common/MiniGameLink';
import { SwalCorrect } from '../../components/common/Swal';

const SquareDetail = () => {

	const { square_id } = useParams();
	const navigate = useNavigate();
	const { language } = useContext(appContext);
	const isPC = useMediaQuery({ minWidth: 992 });

	const isEng = language === "English"
	const squareDetailContent = translations.squareDetail;

	const { squareDetail } = useSelector(state => structuredClone(state.square));
	const personaList = squareDetail?.personas ?? [];
	const [isLogined, setLogined] = useState(false);
	const [filter, setFilter] = useState("All");
	const [miniGameLink,setMiniGameLink] = useState("");

	useEffect(() => {
		(async () => {
			const token = await getToken();
			setLogined(!!token);
		})();
	}, []);

	const { ref: tabRef, entry } = useIntersection({
		root: null,
		rootMargin: '0px',
		threshold: 1,
	});

	const { handleSquarePointAlert, setHideBottomNav } = useOutletContext();

	const handleMiniGameClick = (url) => {
		setMiniGameLink(url);
		setHideBottomNav(!!url);
	}

	const sortedTab = useMemo(() => {
		let sorted = squareDetail?.square_tab?.sort((a, b) => {
			return a.tab_order - b.tab_order;
		});

		if (personaList && personaList.length > 0) {
			sorted.push(
				{
					id: 'persona',
					tab_name: "ペルソナ",
					tab_name_en: "Persona",
					isPersona: true,
				}
			)
		}
		if (squareDetail?.draw_chains && squareDetail?.draw_chains.length > 0) {
			sorted.push(
				{
					id: 'drawchain1',
					tab_name: "ドローチェーン",
					tab_name_en: "Draw chain",
					isDrawchain: true,
				}
			)
		}
		const ad_square = squareDetail?.child_squares;
		if (ad_square?.length > 0) {
			sorted.push(
				{
					id: 'ad_square',
					tab_name: "アドスクエア",
					tab_name_en: "Ad Square",
					isAdSquare: true
				}
			)
		}
		return sorted;
	}, [squareDetail?.square_tab])

	const tabContent = sortedTab?.map((it, index) => {
		if (it.isPersona) {
			if (["All", "persona"].includes(filter) && personaList && personaList.length > 0) {
				return (
					<ItemCarousel
						key={`tabContent_key_${index}`}
						collection
						title={translations.formatString(squareDetailContent?.personas)}
						items={personaList}
						rootClassName="mb-45px"
						browsersize={window.innerWidth}
						hideLine={true}
						token_type_id={TokenType.Persona}
						isunboldheader
					/>
				)
			}
			return null;
		} else if (it.isDrawchain) {
			if (["All", "drawchain1"].includes(filter) && squareDetail?.draw_chains && squareDetail?.draw_chains.length > 0) {
				return (
					<ItemCarousel
						square_id={square_id}
						key={`tabContent_key_${index}`}
						drawchain
						title={translations.formatString(squareDetailContent?.drawChains)}
						items={squareDetail?.draw_chains}
						rootClassName="mb-45px"
						browsersize={window.innerWidth}
						hideLine={true}
					/>
				)
			}
			return null;
		}
		else if (it.isAdSquare) {
			if (["All", "ad_square"].includes(filter) && squareDetail?.child_squares && squareDetail?.child_squares?.length > 0) {
				return (
					<ItemCarousel
						square
						key={`tabContent_key_${index}`}
						title={translations.formatString(squareDetailContent?.adSquare)}
						items={squareDetail?.child_squares}
						rootClassName="mb-45px"
						browsersize={window.innerWidth}
						hideLine={true}
					/>
				)
			}
			return null;
		}
		else if (it?.square_tab_mini_games?.length > 0) {
			const miniGames = [...it.square_tab_mini_games];
			return (
				<>
				{
					["All",it.id].includes(filter) && miniGames.length > 0 &&
					<ItemCarousel 
						onClick={handleMiniGameClick}
						key={`tabContent_key_${index}`}
						miniGame
						title={isEng ? it.tab_name_en : it.tab_name}
						items={miniGames}
						rootClassName="mb-45px"
						browsersize={window.innerWidth}
						hideLine={true}
					/>
				}
				</>
			)
		}
		else {
			const mixed = [...it.square_tab_news, ...it.square_tab_games];
			mixed.sort(sortByPublishedDate);
			return (
				<>
					{["All", it.id].includes(filter) && mixed.length > 0 &&
						<ItemCarousel
							key={`tabContent_key_${index}`}
							topics
							title={isEng ? it.tab_name_en : it.tab_name}
							items={mixed}
							rootClassName="mb-45px"
							browsersize={window.innerWidth}
							hideLine={true}
						/>}
				</>
			)
		}
	})

	const topImage = squareDetail?.top_news?.image_url;

	return (
		<>
			<div className="w-100 position-relative">
				<MiniGameLink url={miniGameLink} onClose={()=>handleMiniGameClick()}/>
				{(!isPC && !entry?.isIntersecting) && (
					<SquareHeader
						name={language === "English" ? squareDetail?.title_en : squareDetail?.title}
						onClickSquarePoint={handleSquarePointAlert}
						image={squareDetail?.square_maker_icon}
						isLogined={isLogined}
						squarePoint={squareDetail?.squarePoint} />)}
				<TabList items={sortedTab}
					tabRef={tabRef}
					filter={filter}
					setFilter={setFilter} />
				{
					filter === "All" && squareDetail?.top_news && (
						isPC ? (
							<TopNewsPC topNews={squareDetail?.top_news} topImage={topImage} className="w-100" square />
						) : (
							<Card
								background_image={squareDetail?.top_news?.image_url}
								top_news={squareDetail?.top_news}
								navigate={navigate}
								isPC={isPC}
							/>)
					)
				}
				<div className="px-2 px-lg-0 my-3 w-100 overflow-hidden">
					{tabContent}
				</div>
				<Footer
					contact={squareDetail?.contact}
					copyright={isEng ? squareDetail?.copyright_text_en : squareDetail?.copyright_text}
					termsOfUse={isEng ? squareDetail?.terms_of_use_en : squareDetail?.terms_of_use}
					privacyPolicy={isEng ? squareDetail?.privacy_policy_en : squareDetail?.privacy_policy}
					lawNotation={squareDetail?.footer_link} />
			</div>
		</>
	);
};

const SquareHeader = ({ image, onClickSquarePoint, isLogined, squarePoint, name }) => {
	const navigate = useNavigate();
	const isEng = translations._language === "en";
	const squareDetailContent = translations?.squareDetail
	return (
		<div
			className='header-animation position-fixed w-100 d-flex align-items-center primary-bg-gradient justify-content-between px-3'
			style={{ height: 75, top: 0, zIndex: 99, gap: 5 }}>
			<div className='d-flex align-items-center' style={{ gap: 20 }}>
				<div
					onClick={() => navigate(-1)}
					className='game-detail-arrow m-0'></div>
				<div className='d-flex flex-center'>
					{isLogined ? <Avatar src={image} width={35} /> : (
						<h1 className='tx-ellipsis text-shadow text-start w-100 fs-20' style={{ margin: `0 0 ${isEng ? 3 : 0}px` }}>
							{name}
						</h1>
					)}
				</div>
				{isLogined && (<div className='d-flex flex-center' style={{ gap: 10 }}>
					<span className='fs-20 text-nowrap'>{numeral(squarePoint).format('0,0')}</span><span className='fs-12' style={{ marginTop: 2 }}>{squareDetailContent?.squarePointUnit}</span>
				</div>)}
			</div>
			{isLogined && (<Button
				disabled
				active
				style={{ aspectRatio: '1 / 1', width: 35 }}
				onClick={onClickSquarePoint}
				className="f-Inter rounded-circle p-0"
				icon={<ArcanaPlusIcon />}>
			</Button>)}
		</div>
	)
}

const Footer = ({
	contact,
	copyright,
	termsOfUse,
	privacyPolicy,
	lawNotation,
}) => {
	const content = translations.squareDetail.footer;
	const [show, setShow] = useState(false);
	const isPC = useMediaQuery({ minWidth: 992 });
	return (
		<div style={{ padding: isPC ? '0 40px' : '0 15px' }}>
			<div className="divider mb-3 mt-3 mx-auto" style={{ width: '100%' }}></div>
			<div className={`fs-18 w-100 d-flex ${isPC ? 'justify-content-between align-items-center' : 'flex-column'}`}>
				{copyright && <p className={`text-start ${isPC ? 'm-b-0' : ''}`}>{copyright}</p>}
				<div style={{
					display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
					gap: 5,
					whiteSpace: 'nowrap',
					flexWrap: 'wrap'
				}}>
					{contact && isValidUrl(contact) ?
						<a href={contact} target='_blank' style={{ textDecoration: 'none' }}>{content.contact}</a>
						: <p style={{ margin: 0 }} onClick={() => setShow(true)}>{content.contact}</p>}
					{termsOfUse && <Seperator />}
					{termsOfUse && (
						<>
							<p style={{ margin: 0, }}><Link target={'_blank'} to={termsOfUse}>{content.termsOfUse}</Link></p>
						</>
					)}
					{privacyPolicy && <Seperator />}
					{privacyPolicy && (
						<>
							<p style={{ margin: 0 }}><Link target={'_blank'} to={privacyPolicy}>{content.privacyPolicy}</Link></p>
						</>
					)}
					{lawNotation && <Seperator />}
					{lawNotation && (
						<>
							<p style={{ margin: 0 }}><Link target={'_blank'} to={lawNotation}>{content.lawNotation}</Link></p>
						</>
					)}
					<ContactModal contact={contact} show={show} onClose={() => setShow(false)} />
				</div>
			</div>
		</div>
	)
}

const Seperator = () => {
	const isPC = useMediaQuery({ minWidth: 992 });
	return (
		<div className={`seperator ${isPC ? 'mx-3' : 'mx-2'}`} style={{ width: 2, height: 30, minWidth: 2 }}></div>
	)
}

export const FilterButton = (props) => (
	<button
		{...props}
		style={{ flex: '0 0 auto' }}
		type="button"
		className={`text-nowrap px-2 pt-2 pb-3 filter-btn square-filter-btn fs-15 ${props.className ?? ""
			} ${props.active ? "active" : ""}`}
	>
		{props.children}
	</button>
);

const Card = ({ background_image, top_news, navigate, isPC }) => {
	const removeStyleTags = (() => {
		const body = (translations._language === "jp" ? top_news?.body : top_news?.body_en) || top_news?.body_en
		const doc = new DOMParser().parseFromString(body, "text/html");
		const styleTags = doc.getElementsByTagName('style');
		for (let i = styleTags.length - 1; i >= 0; i--) {
			const tag = styleTags[i];
			tag.parentNode.removeChild(tag);
		}
		const newHtml = new XMLSerializer().serializeToString(doc);

		return newHtml
	})()

	const content = createMarkup(removeStyleTags)

	return (
		<div
			className="w-100 min-h-220px my-3 position-relative pointer"
			onClick={() => navigate(`/news_detail/${top_news.id}`)}
		>
			{background_image && <img
				style={{
					width: "100%",
					height: "100%",
					objectFit: "cover",
				}}
				src={background_image}
				className="position-absolute top-0 left-0 z-n1"
			/>}
			<div className="d-flex flex-column justify-content-end position-relative z-1 px-25px py-2 w-100 min-h-220px light-gradient">
				<h1 className="m-0 fs-18">
					{(translations._language === "jp"
						? top_news?.title
						: top_news?.title_en) || top_news?.title_en}
				</h1>
				<p
					className={`fs-12 m-0 my-2 ${isPC ? "" : "multiline-ellipsis"}`}
				>{content}</p>
			</div>
		</div>
	);
};

function sortByPublishedDate(a, b) {
	if (moment(a.published).isAfter(b.published)) {
		return -1;
	}
	if (moment(a.published).isBefore(b.published)) {
		return 1;
	}
	return 0;
}

export function isValidUrl(string) {
	try {
		new URL(string);
		return true;
	} catch (err) {
		return false;
	}
}

export default SquareDetail;
