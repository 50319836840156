import React, { useEffect, useState, useContext, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Item from '../../components/common/Item'
import { WalletTitle } from '../../components/common/Title'
import AppWrapper from '../../components/wrappers/AppWrapper'
import RPC from "../../components/common/web3RPC";
import { Web3authContext } from '../../context/web3authContext'
import { OnLoading } from '../../components/common/OnLoading'
import { translations } from "../../services/localization";
import { MenuSearchBox } from '../../components/common/Form'
import { useDidUpdateEffect } from '../../services/hook'
import { formatNumber, handleSearch, isJP } from '../../components/common/commonFnc'
import { postData } from '../../services/fetch'
import { useDispatch, useSelector } from 'react-redux'
import { Button as SButton } from '../squares/Button'
import { OrderStatus, TokenType } from '../../components/common/enum';
import { useMediaQuery } from 'react-responsive'
import { getWalletAddress, setRedirect, setWalletAddress } from '../../services/storage';
import { isChrome, isIOS, isMobile, isMobileOnly, isMobileSafari } from 'react-device-detect'
import { AuthContext } from '../../context/authContext'
import InfiniteScrollContainer from '../../components/common/InfiniteScrollContainer'
import { clearItems, toggleSelectionMode } from '../../store/MyArcana'

const WalletArcana = ({ token_type_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmPh = useMediaQuery({ maxWidth: 452 });
  const isLgPh = useMediaQuery({ maxWidth: 576 });
  const [items, setItems] = useState([]);
  const { wallet_address, init, setWallet_address, provider, loginWeb3 } = useContext(Web3authContext);
  const { updateLastSeenTokenDate } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const isSelectionMode = useSelector(state => state.myArcana.isSelectionMode);
  const selectedItems = useSelector(state => state.myArcana.selectedItems);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false)
  const [itemsLimit, setItemsLimit] = useState(null)

  const [search, setSearch] = useState('');
  const [openSearch, setOpenSearch] = useState(true);
  const searchInputRef = useRef();
  const [total, setTotal] = useState(0)
  const [type, setType] = useState(null)
  const [onClickSellFlg, setOnClickSellFlg] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [total_bid, setTotal_bid] = useState({
    jpy: 0,
    anm: 0
  })

  const toggleOpenSearch = () => setOpenSearch(openSearch => !openSearch);

  const handleClickBack = () => {
    setSearch('');
    toggleOpenSearch();
  }

  useEffect(() => {
    setWallet_address(getWalletAddress())
    if (!getWalletAddress()) {
      navigate('/wallet')
    }
  }, []);

  useEffect(() => {
    if (openSearch && searchInputRef.current) {
      if (!searchInputRef.current.value && search) {
        searchInputRef.current.value = search;
      }
    }
  }, [openSearch])

  useEffect(() => {
    if (getWalletAddress()) {
      setWallet_address(getWalletAddress())
    }
    if (provider) {
      console.log("PROVIDER SET")
      setLoading(false)
      const rpc = new RPC(provider);
      rpc.getAccounts().then(address => {
        console.log({ address })
        setWalletAddress(address)
        setWallet_address(address)
        if (onClickSellFlg) {
          makeAskOffer()
        }
      });
    }
  }, [provider]);

  // calculate screen display for limit size
  function calculateSize() {
    let body_size = 0;
    if (window.innerWidth > 991) {
      body_size = window.innerWidth - 260; // reduct side bar menu px
    } else {
      body_size = window.innerWidth;
    }
    let size = body_size / 226; // one item has max width 210 px
    if (size - Math.trunc(size) < 0.1) {
      // px 0.1< cann't diplay full item so reduce 1 item limit
      size = Math.trunc(size) - 1;
    }
    else {
      size = Math.trunc(size);
    }
    return size;
  }

  const handleSearchClear = () => {
    searchInputRef.current.value = "";
    setSearch("")
    searchInputRef.current.focus();
  }

  const onSearchSubmit = async (e) => {
    e.preventDefault();
    setSearch(searchInputRef.current.value || '');
  }

  const getItems = async (token_type_id, search, page = 1) => {
    if (itemsLimit) {
      if (page === 1) {
        setLoading(true);
        setItems([]);
      }
      console.log(itemsLimit)
      const response = await handleSearch(search, { token_type_id }, "DESC", getWalletAddress(), page, false, itemsLimit);
      var { tokens, total, has_next_page, total_bid } = response || {};
      console.log(response);
      setHasMore(has_next_page)
      setTotal(total)
      setTotal_bid(total_bid)
      setItems(tokens || [])
      setLoading(false);
      updateLastSeenTokenDate();
    }
  }

  console.log(total_bid);

  useEffect(() => {
    getItems(token_type_id, '', 1);

    return () => {
      setItems([]);
    }
  }, [token_type_id])

  useEffect(() => {
    if (itemsLimit !== null) {
      setFetchCount(count => count + 1)
    }
  }, [itemsLimit])

  useDidUpdateEffect(() => {
    if (page > 1) {
      setPage(1);
    } else {
      setFetchCount(count => count + 1);
    }
  }, [search])

  useDidUpdateEffect(() => {
    setFetchCount(count => count + 1);
  }, [page])

  useDidUpdateEffect(() => {
    getItems(token_type_id, search, page);
  }, [fetchCount])

  const content = translations.walletArcana
  const commonlist = translations.common

  const selectButton = useMemo(() => {
    return (
      <Button
        key="select-button"
        onClick={() => {
          if (isSelectionMode) {
            dispatch(clearItems());
          }
          dispatch(toggleSelectionMode());
        }}>
        {isSelectionMode ? "Cancel" : "Select"}
      </Button>
    )
  }, [isSelectionMode])

  const makeAskOffer = () => {
    setOnClickSellFlg(true);
    if (!provider) {
      setLoading(true);
      try {
        postData(`user/get-web3auth-token`).then(response => {
          const _id_token = response.data.web3auth_token
          loginWeb3(_id_token)
        })
      } catch (error) {
        console.log(error);
      }
      return;
    }
    navigate('/offer/ask', { state: { type: OrderStatus.Ask, token: null } })
  }

  const AdditionalBottom = () => {
    return (
      <div className={`d-flex ${!isPC ? 'justify-content-between' : ''}`} style={{ gap: 10 }}>
        <SButton
          disabled={selectedItems.length < 2}
          active
          reverse
          onClick={makeAskOffer}
          icon={<div className='game-detail-arrow m-0' style={{ filter: 'brightness(150%) saturate(0%)', rotate: '180deg' }}></div>}
          className="flex-1 fs-20"
          style={{ height: 50, borderRadius: 15 }}>make ASK</SButton>
        <SButton
          active
          disabled={selectedItems.length < 2}
          className="fs-20"
          style={{ height: 50, borderRadius: 15, minWidth: 80 }}>{selectedItems.length}</SButton>
      </div>
    )
  }

  const isPC = useMediaQuery({ minWidth: 992 });

  return (
    <>
      <AppWrapper title={translations.formatString(content.headerTitle)} {...((isSelectionMode && !isPC) ? ({ additionalBottom: <AdditionalBottom /> }) : ({}))}>

        <div className={`d-flex align-content-center align-items-center mb-3 ${isPC ? '' : 'justify-content-between'}`} style={{ gap: 10 }}>
          <WalletTitle title={Number(token_type_id) === Number(TokenType.Arcana) ? content.mainTitle : content.myPersona} total={total} className="m-0" />
          {/*Number(token_type_id) === Number(TokenType.Persona) &&
            <div className='d-flex'>
              <PersonaWalletSendIcon className={`${type === 'persona' ? 'active-icon' : ''} wallet-send-icon m-r-15 pointer`} onClick={() => type === 'persona' ? setType(null) : setType('persona')} />
              { <div className={`wallet-send-icon purple-circle text-center pointer ${type === 'persona_reality' ? 'purple-bg' : ''}`} onClick={() => type === 'persona_reality' ? setType(null) : setType('persona_reality')}>
                <PersonaRealityIcon className={`${type === 'persona_reality' ? 'active-icon' : 'purple-icon'} wallet-circle-icon`} />
              </div> }
            </div>*/
          }
          {/* {Number(token_type_id) === Number(TokenType.Arcana) && selectButton} */}
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='my-4 d-flex flex-1' style={{ maxWidth: 400 }}>
            {openSearch && !isPC && (<div className={`amount-box-container f-condensed ${isPC && 'w-100'}`} style={{ maxWidth: 354 }}>
              <div>{formatNumber(Number(total_bid?.jpy))} JPY</div>
              <div>{formatNumber(Number(total_bid?.anm))} ANM</div>
              <div className={`amount-box-title ${isJP() && 'f-notosan fs-14 jp-title'}`}>{translations.formatString(content.totalBid)}</div>
            </div>)}

            {isPC && (<div className={`amount-box-container f-condensed w-100}`} style={{ maxWidth: 354, height: 40 }}>
              <div>{formatNumber(Number(total_bid?.jpy))} JPY</div>
              <div>{formatNumber(Number(total_bid?.anm))} ANM</div>
              <div className={`amount-box-title ${isJP() && 'f-notosan fs-14 jp-title'}`}>{translations.formatString(content.totalBid)}</div>
            </div>)}

            {
              !isPC && (
                <div className={`${openSearch && "d-flex align-items-center"} position-relative flex-1`} style={{ width: 40, height: 40 }}>
                  <form onSubmit={onSearchSubmit} onClick={openSearch ? toggleOpenSearch : () => { }}>
                    <MenuSearchBox openSearch={!openSearch} containerClassName={`toggled-searchbox ${openSearch ? '' : 'open'}`} ref={searchInputRef} inputText={searchInputRef.current ? searchInputRef.current.value : ""} onClose={handleClickBack} onClear={handleSearchClear} />
                  </form>
                </div>
              )
            }
          </div>

          <div className={`${!isPC ? "d-none" : ""}`} style={{ width: 354, height: 40 }}>
            <form onSubmit={onSearchSubmit}>
              <MenuSearchBox ref={searchInputRef} inputText={searchInputRef.current ? searchInputRef.current.value : ""} onClose={handleClickBack} onClear={handleSearchClear} />
            </form>
          </div>
        </div>
        {/* {(isPC && isSelectionMode) && (<div className='mb-2'>
          <AdditionalBottom />
        </div>)} */}
        {
          loading ? <OnLoading /> :
            <div >

              <InfiniteScrollContainer
                onChangePage={setPage}
                hasMore={hasMore}
                newItems={items}
                itemSize={isLgPh ? 210 : 226}
                leftSpace={0}
                rightSpace={isLgPh ? 0 : 16}
                limit={itemsLimit}
                setLimit={setItemsLimit}
                total={total}
                renderedContainerClassName='row token-list m-0'
                renderedComponent={(item, index, ref) =>
                  (type === null || type === item.name) &&
                  <Item
                    ref={ref}
                    key={`wallet_arcana_token_${index}`}
                    item={item}
                    isunboldheader
                    onClick={() => navigate(`/wallet/${TokenType.getRouteName(token_type_id)}/detail/${item.token_id}`)} />}
              />

            </div>
        }
      </AppWrapper>
    </>
  )
}

const Button = ({ children, ...rest }) => {
  return (
    <button className="bg-pink text-darkblue border-0 outline-0" style={{
      borderRadius: 2,
      border: '1px solid #BF96FF'
    }} {...rest}>{children}</button>
  )
}

export default WalletArcana
