/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ItemDetail } from '../../components/common/Item';
import AppWrapper from '../../components/wrappers/AppWrapper';
import { postData } from '../../services/fetch';
import { OnLoading } from '../../components/common/OnLoading';
import { getWalletAddress, removeBidInfo, setRedirect, setWalletAddress } from '../../services/storage';
import { translations } from '../../services/localization';
import { OrderStatus, TokenType } from '../../components/common/enum';
import { Web3authContext } from '../../context/web3authContext';
import { useLocation } from 'react-router-dom';
import RPC from "../../components/common/web3RPC";
import { calculateBN, getArcanaDetail, swalText } from '../../components/common/commonFnc';
import { AuthContext } from '../../context/authContext';
import { Button } from '../squares/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedItem } from '../../store/MyArcana';
import { Btn } from '../../components/common/Button';
import Modal from '../../components/common/Modal';
import { SwalCorrect, SwalSettings } from '../../components/common/Swal';
import { useMediaQuery } from 'react-responsive';

const WalletArcanaDetail = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token_type_id } = props;
    const { token_id } = useParams();
    const [item, setItem] = useState(null);
    const [callbackUrl, setCallbackUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const buttonlist = translations.buttonlist;
    const [id_token, setIdToken] = useState(null);
    const [onClickSellFlg, setOnClickSellFlg] = useState(false);
    const [animaDecimal, setAnimaDecimal] = useState(undefined);
    const [animaAmount, setAnimaAmount] = useState(undefined);
    const [scores_changed, setScores_changed] = useState(undefined);
    const [displayModal, setDisplayModal] = useState(false)
    const [extractShard, setExtractShard] = useState(false)

    const search = useLocation().search;
    const { absorber } = useLocation().state || {};
    const { user } = useContext(AuthContext)

    const { isSelectionMode } = useSelector(state => state.myArcana);
    // for test purpose
    // const absorber = {
    //     "token_id": "266288103425",
    //     "owner": "0x30fFd54fE0D1aeFF01ef556Ff359517591Fa1Db4",
    //     "elements": 1,
    //     "FOR": -10,
    //     "ABS": 9,
    //     "DFT": 0,
    //     "MND": 0,
    //     "INT": 6,
    //     "EXP": 0,
    //     "anima": 1000000000000000000,
    //     "absorbed_count": 1,
    //     "birthday": "2023-04-20",
    //     "birthday_datetime": "2023-04-20T15:42:16",
    //     "metadata": {
    //         "name": "persona",
    //         "creator": "popo",
    //         "image": "QmYCQ3oX4M8snuesMah8cCfH5z9wuDWZm9rxLmZT5z1BzH",
    //         "description": ""
    //     },
    //     "name": "persona",
    //     "creator": "popo",
    //     "image": "https://ark2-ipfs.anicana-api.akqjt.io/ipfs/QmYCQ3oX4M8snuesMah8cCfH5z9wuDWZm9rxLmZT5z1BzH",
    //     "token_type_id": "3",
    //     "greenStar": null,
    //     "feature": [],
    //     "details": {
    //         "blockchain": "ANICANA",
    //         "token_standard": "ERC-721"
    //     }
    // }
    const query = new URLSearchParams(search);

    const { setWallet_address, provider, loginWeb3, loginIfNotLogin } = useContext(Web3authContext);
    const language = translations._language;

    const getItem = async (token_id, retry = 0) => {
        setLoading(true);
        let token = await getArcanaDetail(token_type_id, token_id, props.preview);
        if (token) {
            setItem(JSON.parse(JSON.stringify(token)));
            setLoading(false);
        } else if (retry < 10) {
            setTimeout(() => {
                getItem(token_id, retry + 1)
            }, 1000);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log({ absorber, item, preview: props.preview })
        if (props.preview && absorber && item) {
            const scores_changed = {
                FOR: item.FOR - absorber.FOR,
                ABS: item.ABS - absorber.ABS,
                DFT: item.DFT - absorber.DFT,
                MND: item.MND - absorber.MND,
                INT: item.INT - absorber.INT,
                EXP: item.EXP - absorber.EXP,
            };
            console.log({ scores_changed })
            setScores_changed(scores_changed)
        }
    }, [absorber, item, props.preview])

    useEffect(() => {
        if (getWalletAddress()) {
            setWallet_address(getWalletAddress())
        }
        if (provider) {
            console.log("PROVIDER SET")
            setLoading(false)
            const rpc = new RPC(provider);
            rpc.getAccounts().then(address => {
                console.log({ address })
                setWalletAddress(address)
                setWallet_address(address)
                if (onClickSellFlg) {
                    makeAskOffer()
                }
            });
            rpc.getAnimaDecimal().then(decimal => setAnimaDecimal(decimal));
        }
    }, [provider]);

    // useEffect(() => {
    //     if (!provider && item && Number(item.token_type_id) === Number(TokenType.Persona) && typeof item.anima === 'number') {
    //         if (!getWalletAddress()) {
    //             navigate(`/login/idms?r=/wallet/persona/detail/${token_id}`)
    //             return;
    //         }
    //         postData(`user/get-web3auth-token`).then(response => {
    //             const _id_token = response.data.web3auth_token
    //             setIdToken(_id_token)
    //         })
    //     }
    // }, [provider, item])

    useEffect(() => {
        if (id_token) {
            loginIfNotLogin(id_token)
        }
    }, [id_token])

    useEffect(() => {
        if (animaDecimal && item && typeof item.anima === 'number') {
            const animaAmount = calculateBN(item.anima, animaDecimal, 2);
            setAnimaAmount(animaAmount);
        }
    }, [animaDecimal, item])

    useEffect(() => {
        if (!token_id) return;

        loginWeb3(id_token, true)
        // console.log(item, getWalletAddress(), item.owner == getWalletAddress())
        getItem(token_id);

        return () => setItem(null);
    }, [token_id])

    useEffect(() => {
        console.log(getWalletAddress())
        if (item) {
            const rpc = new RPC(provider);
            rpc.getAnimaDecimal().then(decimal => setAnimaDecimal(decimal));
        }
    }, [item])

    useEffect(() => {
        removeBidInfo();
        let callbackUrlAddHashParam = query.get('r')
        if (callbackUrlAddHashParam) {
            setCallbackUrl(decodeURIComponent(callbackUrlAddHashParam))
        }

    }, []);

    const makeAskOffer = () => {
        setOnClickSellFlg(true)
        if (!provider) {
            setLoading(true)
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                loginWeb3(_id_token)
            })

            return;
        }
        navigate('/offer/ask', { state: { type: OrderStatus.Ask, token: item } })
    }

    const makeBidOffer = () => {
        const isJP = translations._language === 'jp';
        // console.log(getWalletAddress(), 'get wallet address', user)
        if (!getWalletAddress() || user.id === undefined || user === null || user == {}) {
            setRedirect(window.location.pathname)
            // navigate('/login/idms')
            const token_type = Number(token_type_id) === Number(TokenType.Persona) ? 'persona' : 'arcana'
            navigate(`/login/idms?r=/wallet/${token_type}/detail/${token_id}`)
            return;
        }
        navigate('/offer/bid', { state: { type: OrderStatus.Bid, token: item } })
    }

    const closeWindow = () => {
        if (callbackUrl) {
            window.location.href = callbackUrl;
            // navigate('/wallet');
        } else {
            // navigate(`/`)
            window.open('', '_self', '');
            window.close();
            navigate('/wallet');
        }
    }

    const SelectionBottom = () => {
        return item && (
            <div className='d-flex justify-content-between' style={{ gap: 10 }}>
                <Button
                    onClick={() => navigate(-1)}
                    className="flex-1 fs-20" active style={{ height: 50, borderRadius: 15 }}>Back</Button>
                <Button
                    onClick={() => {
                        dispatch(setSelectedItem(item));
                        navigate(-1);
                    }}
                    className="flex-1 fs-20" active style={{ height: 50, borderRadius: 15 }}>Select</Button>
            </div>
        )
    }


    const statusChangeHandler = () => {
        SwalCorrect.fire({
            title: "The extraction of the ARCANA has been successfully started. It takes 5m-45h to complete the process.",
            showCancelButton: false,
            customClass: {
                cancelButton: '',
            }
        }).then((result) => {
            setExtractShard(true)
            setDisplayModal(false)
        })
    }

    const isPC = useMediaQuery({ minWidth: 992 })
    const [modalPosition, setModalPosition] = useState(false)
    useEffect(() => {
        if (modalPosition)
            isPC ? onChangeHandler() : setDisplayModal(true)
    }, [modalPosition]);

    const onChangeHandler = () => {
        SwalSettings.fire({
            text: "Are you sure you want extract this ARCANA? Once the extraction has been completed it cannot be undone, and the ARCANA will disappear.",
            confirmButtonText: swalText("extract"),
            cancelButtonText: swalText("cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                statusChangeHandler()
            }
            setModalPosition(!modalPosition)
        });
    }


    const AdditionalBottom = () => {
        return (
            <div>
                {
                    !item ? <></> : props.preview ?
                        // <button className='btn btn-custom-active btn-big w-100' onClick={() => closeWindow}>Close</button>
                        <div className='d-flex justify-content-between' style={{ maxWidth: 494 }}>
                            {
                                Number(token_type_id) !== Number(TokenType.Persona) ?
                                    <>
                                        <a className="btn-custom-5 fw-400 fs-25 w-100 me-3" style={{ height: 50 }} onClick={() => closeWindow()}>{translations.formatString(buttonlist.closeBtn)}</a>
                                        <a className="btn-custom-5 fw-400 fs-25 ms-0 ms-lg-3" style={{ height: 50, width: '100%', minWidth: 200 }} onClick={() => navigate(`/wallet/${TokenType.getRouteName(token_type_id)}`)}>{translations.formatString(buttonlist.seeWalletBtn)}</a>
                                    </> :
                                    <a className="btn-custom-5 fw-400 fs-25" style={{ height: 50, width: '100%', minWidth: 200, maxWidth: 494 }} onClick={() => navigate(`/wallet/${TokenType.getRouteName(token_type_id)}`)}>{translations.formatString(buttonlist.seeWalletBtn)}</a>
                            }
                        </div>
                        :
                        (!loading ?
                            item.owner === getWalletAddress() ?
                                <div className='d-flex justify-content-between'>
                                    {item.is_during_non_active || item.is_during_trading || item.is_during_error
                                        ? <Btn disabled className="btn-custom-5 w-100 max-w-unset">{translations.formatString(item.is_during_error ? buttonlist.duringError : buttonlist.duringTrading)}</Btn>
                                        :
                                        extractShard ? <Btn
                                            disabled
                                            className={`btn-custom-5 w-100 max-w-unset`}
                                        // onClick={makeAskOffer}
                                        >
                                            extracting... 1h 45m remaining

                                        </Btn> :
                                            <>
                                                <Btn
                                                    className={`btn-custom-5 w-100 me-3 btn-sell`}
                                                    onClick={makeAskOffer}
                                                >
                                                    {translations.formatString(buttonlist.sellBtn)}

                                                </Btn>
                                                <Btn
                                                    className={`w-100 btn-send me-3`}
                                                    onClick={() => navigate(`/wallet/send/address`, { state: { token: item, token_type_id, token_id } })}
                                                >
                                                    {translations.formatString(buttonlist.sendBtn)}
                                                </Btn>
                                                <Btn
                                                    className={`btn-custom-5 w-100 btn-extract`}
                                                    // onClick={() => navigate(`/wallet/shards`)}
                                                    onClick={() => setModalPosition(true)}
                                                >
                                                    {translations.formatString(buttonlist.extractBtn)}

                                                </Btn>
                                            </>
                                    }
                                </div> :
                                <div className='d-flex justify-content-center'>
                                    {item.is_during_trading || item.is_during_error
                                        ? <Btn disabled className="btn-custom-5 w-100">{translations.formatString(item.is_during_error ? buttonlist.duringError : buttonlist.duringTrading)}</Btn>
                                        : <Btn className="btn-custom-5 w-100 make-bid" onClick={makeBidOffer}>{translations.formatString(buttonlist.makeBidBtn)}</Btn>
                                    }
                                </div>
                            : "")
                }
            </div>
        )
    }

    const metaTitle = `${(item && item.metadata && item.metadata.name) ? item.metadata.name : ''}｜ANICANA θυρα（アニカナテュラー）`
    const metaDescription = `${(item && item.metadata && item.metadata?.description) ? item.metadata?.description : ''}｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています`

    return (
        <>

            <AppWrapper title={props.preview ? Number(token_type_id) === Number(TokenType.Persona) ? translations.formatString(translations.walletAbsorb.headerTitle) : 'generate ARCANA' : (Number(token_type_id) === Number(TokenType.Persona) ? 'PERSONAs' : 'ARCANA')}
                hideBottomNav={props.preview}
                // noLayout={props.preview}
                disableSidebar={props.preview}
                hideNav={props.preview}
                additionalBottom={<div className='d-block d-lg-none'>{isSelectionMode ? <SelectionBottom /> : <AdditionalBottom />}</div>}
                metaTitle={metaTitle}
                metaDescription={metaDescription}
            >
                {
                    item && !loading ? <ItemDetail item={item} animaAmount={animaAmount} additionalBottom={isSelectionMode ? <SelectionBottom /> : <AdditionalBottom />} scores_changed={scores_changed} /> : <OnLoading />
                }
                {/* <div style={{ marginBottom: 100 }}>
                <div style={{marginBottom:item<1&&'55vh'}}></div>
                <Footer/>
            </div> */}


            </AppWrapper>
            {
                <Modal displayModal={displayModal} onClose={() => setDisplayModal(!displayModal)}>
                    <div className='flex-center text-center flex-column'>
                        <span className="fw-500">
                            Are you sure you want extract this ARCANA? Once the extraction has been completed it cannot be undone, and the ARCANA will disappear.
                        </span>
                    </div>
                    <div className='flex-center flex-column w-90 mx-auto px-3'>
                        <Btn type='button' onClick={statusChangeHandler} className='mb-4 w-100' style={{ margin: '33px 0' }}>
                            extract
                        </Btn>
                        <Btn intent="outline" type='button' onClick={() => setDisplayModal(false)} className='w-100'>
                            cancel
                        </Btn>
                    </div>
                </Modal>
            }
        </>
    )
}

export default WalletArcanaDetail
