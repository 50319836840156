import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TokenType } from '../../components/common/enum'
import { CategoryNavRow } from '../../components/common/FlexNavRow'
import Item from '../../components/common/Item'
import { WalletPageTitle } from '../../components/common/Title'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { getData, postData } from '../../services/fetch'
import { getWalletAddress, getValue } from '../../services/storage'
import { translations } from "../../services/localization";
import { OnLoading } from '../../components/common/OnLoading'
import RPC from "../../components/common/web3RPC";
import { handleSearch, setUserTokens } from '../../components/common/commonFnc';
import { MenuSearchBox } from "../../components/common/Form";
import Pagination from '../../components/common/pagination'
import data from '../../data'
import { isChrome, isIOS, isMobile, isMobileOnly, isMobileSafari } from 'react-device-detect'

const WalletAbsorbList = ({ type = 'absorb' }) => {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const searchInputRef = useRef();
    const [last_page, setLast_page] = useState(1);
    const [page, setPage] = useState(1);
    const [token_type_id, setToken_type_id] = useState(undefined);
    const params = useParams();
    const param_token_type_id = params.token_type_id;
    const state = useLocation().state;
    const commonlist = translations.common

    const getItems = async (token_type_id, token_name, page = 1) => {
        if (token_type_id) {
            setLoading(true);
            var { tokens, last_page } = await handleSearch(token_name, { token_type_id }, "DESC", getWalletAddress(), page);
            // for testing Purpose
            // if (Number(token_type_id) === Number(TokenType.Persona)) {
            //     tokens.push(data.dummy_persona);
            // }
            setItems(tokens || []);
            setLast_page(last_page);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (type === 'drawchains') {
            setToken_type_id(TokenType.Persona);
        } else {
            setToken_type_id(param_token_type_id);
        }
    }, [type, param_token_type_id])

    useEffect(() => {
        getItems(token_type_id);

        return () => {
            setItems([]);
        }
    }, [token_type_id])

    const content = translations.walletAbsorbList

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (!searchInputRef.current) return
        getItems(token_type_id, searchInputRef.current.value);
    }
    const handleSearchClear = () => {
        searchInputRef.current.value = "";
        getItems(token_type_id);
    }

    useEffect(() => {
        getItems(token_type_id, searchInputRef.current.value, page);
    }, [page])

    const handleClick = token_id => {
        if (type === 'drawchains') {
            navigate(`/squares/${params.square_id}/draw-chains/choose/token/${token_type_id}/${token_id}`);
        } else {
            navigate(`/wallet/choose/token/${token_type_id}/${token_id}`, { state });
        }
    }

    return (
        <AppWrapper title={translations.formatString(content.headerTitle)} disableSideContentScroll>
            <div className=''>
                <WalletPageTitle title={translations.formatString(Number(token_type_id) === Number(TokenType.Persona) ? content.mainAbsorberTitle : content.mainTargetTitle)} />
                <div className='divider' style={{ marginTop: 10, marginBottom: 21 }}></div>
                <div className='m-b-16'>
                    <form className='mx-auto' onSubmit={handleSearchSubmit} style={{ maxWidth: 568 }}>
                        <MenuSearchBox ref={searchInputRef} inputText={searchInputRef.current ? searchInputRef.current.value : ""} onClose={handleSearchClear} onClear={handleSearchClear} />
                    </form>
                </div>
            </div>
            <div className={`overflow-scroll overflow-hidden-x ${isChrome ? 'p-b-100' : 'p-b-85'} p-b-lg-0`} style={{
                height: `calc(100vh - ${(isMobileSafari || (isMobile && isIOS)) ? '401' : isMobileOnly ? '351' : '186'}px`
            }}>
                <div className='row' style={{ margin: '0px -5px' }}>
                    {
                        !loading
                            ? (items.length>0?<>
                                {items.map((item, index) =>
                                    <div key={index} className='col-6 col-sm-4 col-md-3' style={{ padding: '0px 5px', maxWidth: 235 }}>
                                        <Item item={item} onClick={() => handleClick(item.token_id)} />
                                    </div>)}
                                <div className='row mb-5'>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <Pagination onPageChange={page => setPage(page)} page={page} last_page={last_page} />
                                    </div>
                                </div>
                            </>:<div className='d-flex flex-center pt-3'>{commonlist.noSearchResult}</div>)
                            : <OnLoading />
                    }
                </div>
            </div>
        </AppWrapper>
    )
}

export default WalletAbsorbList
