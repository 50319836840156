import React, { useEffect, useState } from 'react'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { OnLoading } from '../../components/common/OnLoading'
import { translations } from '../../services/localization';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderStatus, ThirdPartyProvider } from '../../components/common/enum';
import { SwalCustom, SwalWrong } from '../../components/common/Swal';
import { getWalletAddress } from '../../services/storage';
import { BrowserView, MobileView } from 'react-device-detect';
import { WalletPageTitle } from '../../components/common/Title';
import { ReactComponent as OctillionLogo } from '../../assets/images/octillion-logo.svg';
import { BackGradientIcon } from '../../components/common/Icon';
import { postData } from '../../services/fetch';
import { SelectBox } from '../../components/common/Form';
import { REACT_APP_MARKETPLACE_ENDPOINT } from '../../config';
import { Btn, LaddaButton } from '../../components/common/Button';

const Offer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { token, type } = location.state || {};
    // const order = { type: OrderStatus.Ask }
    const content = translations.offer;

    const [loading, setLoading] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(undefined);
    const checkOctillionUser = async () => {
        setLoading(true);
        const wallet_address = getWalletAddress();
        if (wallet_address) {
            const response = await postData(`user/check`, { wallet_address });
            setLoading(false)
            if (response.ok) {
                const isOctillionUser = response.data.user_exists;
                if (isOctillionUser) {
                    navigate('/offer/choose/currency', { state: { token, type } });
                } else {
                    SwalCustom.fire({
                        showConfirmButton: false,
                        showCloseButton: true,
                        customClass: {
                            closeButton: 'swal2-custom-close mt-3'
                        },
                        title: (<OctillionLogo style={{ width: 100, marginTop: 8 }} />),
                        html: (<OctillionCreateContent />)
                    });
                }
            }
        }
    }

    const handleSelectProvider = () => {
        if (selectedProvider) {
            switch (selectedProvider) {
                case ThirdPartyProvider.Octillion: checkOctillionUser(); break;
                default: break;
            }
        }
    }

    return (
        <AppWrapper sidebarWrapperDivClassName={'p-t-8'} title={translations.formatString(type === OrderStatus.Ask ? content.AskOffer : content.BidOffer)}
            disableFooter
            additionalBottom={
                <MobileView>
                    <OfferAdditionBtn onCancel={() => navigate(-1)} onSubmit={handleSelectProvider} submitEnabled={selectedProvider} loading={loading} />
                </MobileView>
            }
        >
            <div className='px-4'>
                <BackGradientIcon onClick={() => navigate(-1)} className="position-absolute" style={{ left: 22, top: 13 }} />
                <div className='d-flex flex-column align-items-center'>
                    <WalletPageTitle className='text-white p-x-16' style={{ lineHeight: 32.5 }} underlineClassName='w-100' title={translations.formatString(content.selectThirdPartyProvider)} hasUnderline />
                    <div className='' style={{ minHeight: 300 }}>
                        <div className='d-flex align-items-center justify-content-center mb-3 p-3 fs-20'>
                            <input
                                onChange={e => setSelectedProvider(e.target.value)}
                                className="form-check-input radio mt-0 me-3"
                                type="radio" value={ThirdPartyProvider.Octillion}
                                checked={ThirdPartyProvider.Octillion === selectedProvider}
                                name="provider"
                            />
                            <OctillionLogo />
                        </div>
                    </div>
                    <BrowserView>
                        <OfferAdditionBtn onCancel={() => navigate(-1)} onSubmit={handleSelectProvider} submitEnabled={selectedProvider} loading={loading} />
                    </BrowserView>
                </div>
            </div>
        </AppWrapper>
    )
}

export default Offer

export function OfferAdditionBtn({ onCancel, onSubmit, submitEnabled, isConfirmBtn, loading }) {
    const buttonlist = translations.buttonlist;
    return (
        <div className='d-flex flex-row justify-content-between align-items-center p-b-7' style={{ maxWidth: '100%' }}>
            <Btn intent="outline" className="me-2" style={{ width: 160 }} onClick={onCancel}>
                {translations.formatString(buttonlist.cancelBtn)}
            </Btn>
            <Btn intent="active" type="button" loading={loading} style={{ width: 160 }} className={`btn btn-custom-active ms-2`} onClick={onSubmit} disabled={!submitEnabled}>
                {translations.formatString(isConfirmBtn ? buttonlist.confirmBtn : buttonlist.nextBtn)}
            </Btn>
        </div>
    )
}

export const OctillionCreateContent = () => {
    const content = translations.offer;
    const buttonlist = translations.buttonlist;
    return (
        <div className='d-flex flex-column align-items-center text-white'>
            <span className='f-monodb fw-400' style={{ fontSize: 35, marginTop: 46, marginBottom: 32 }}>Create Account</span>
            <span className='f-regular fw-400 text-justify pre-wrap' style={{ fontSize: 16, marginBottom: 74 }}>
                {content.createOctillionParagraph}
            </span>
            <span className='f-regular fw-500 text-center' style={{ fontSize: 13 }}>
                {content.nonOctillionText}
            </span>
            <Btn intent="outline" type="button" style={{ marginBottom: 36 }} onClick={() => window.location.href = REACT_APP_MARKETPLACE_ENDPOINT + "register?r=" + window.location.href}>{translations.formatString(buttonlist.createOctillionBtn)}</Btn>
        </div>
    )
}