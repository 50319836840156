import React from 'react'
import CategoryFilter from './CategoryFilter';
import { useMediaQuery } from 'react-responsive';
import { useScrollDirection } from '../../hooks/useScrollDirection';

const SubNav = ({ subNavItems, onChange }) => {
    const isPC = useMediaQuery({ minWidth: 992 });
    const scrollDirection = useScrollDirection({ wrapper: true });
    return (
        <div
            className={`ps-3 ps-lg-0 sticky-top ${isPC ? '' : 'primary-bg-gradient'} ${scrollDirection === null || scrollDirection === 'up' ? 'visible opacity-100' : 'invisible opacity-0'}`} style={{ transition: 'all ease-in .3s' }}>
            <CategoryFilter categories={subNavItems} onChange={onChange} />
            <div className='divider mt-3' style={isPC ? { width: '95%', borderWidth: 0.4 } : { borderWidth: 0.4 }}></div>
        </div>
    )
}

export default SubNav

export const newsSubNavItems = [
    {
        id: 'allnews',
        name: 'All',
        to: '/news',
        selected: true,
        hasDivider: true,
    },
    {
        id: '',
        name: 'ARCANA',
        to: '/tokens/1'
    },
    {
        id: '',
        name: 'Games',
        to: '/products'
    },

    {
        id: '',
        name: 'PERSONA',
        to: '/tokens/2'
    },
]
