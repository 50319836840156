import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TokenType } from '../../components/common/enum';
import { Footer } from '../../components/common/Footer';
import { ItemDetail } from '../../components/common/Item';
import AppWrapper from '../../components/wrappers/AppWrapper';
import { getData, postData } from '../../services/fetch';
import { OnLoading } from "../../components/common/OnLoading";
import { strings, translations } from '../../services/localization';
import data from '../../data';
import RPC from "../../components/common/web3RPC";
import { Web3authContext } from '../../context/web3authContext';
import { getWalletAddress, setWalletAddress } from '../../services/storage';
import { calculateBN, getArcanaDetail } from '../../components/common/commonFnc';
import { useMediaQuery } from 'react-responsive';

const WalletSendToken = ({ type = 'send' }) => {
    const navigate = useNavigate();
    const { token_type_id, token_id } = useParams();
    const [item, setItem] = useState(null);
    const state = useLocation().state;
    const [animaDecimal, setAnimaDecimal] = useState(undefined);
    const [animaAmount, setAnimaAmount] = useState(undefined);
    const [id_token, setIdToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const { setWallet_address, provider, init, loginWeb3, loginIfNotLogin } = useContext(Web3authContext);
    const buttonlist = translations.buttonlist;
    const wallet = translations.wallet;
    const { square_id } = useParams();

    useEffect(() => {
        if (getWalletAddress()) {
            setWallet_address(getWalletAddress())
        }
        if (provider) {
            console.log("PROVIDER SET")
            setLoading(false)
            const rpc = new RPC(provider);
            rpc.getAccounts().then(address => {
                console.log(address)
                setWalletAddress(address)
                setWallet_address(address)
            });
            rpc.getAnimaDecimal().then(decimal => setAnimaDecimal(decimal));
        }
    }, [provider]);

    useEffect(() => {
        if (!provider && item && Number(item.token_type_id) === Number(TokenType.Persona) && typeof item.anima === 'number') {
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                setIdToken(_id_token)
            })
        }
    }, [provider, item])

    useEffect(() => {
        if (id_token) {
            loginIfNotLogin(id_token)
        }
    }, [id_token])

    useEffect(() => {
        if (animaDecimal && item && typeof item.anima === 'number') {
            const animaAmount = calculateBN(item.anima, animaDecimal, 2);
            setAnimaAmount(animaAmount);
        }
    }, [animaDecimal, item])

    const getItem = async (token_id) => {
        setLoading(true);
        // for testing purpose
        // if(Number(token_type_id) === Number(TokenType.Persona)) {
        //     setItem(JSON.parse(JSON.stringify(data.dummy_persona)));
        //     return;
        // }
        let token = await getArcanaDetail(token_type_id, token_id);
        if (token) {
            setItem(JSON.parse(JSON.stringify(token)));
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!token_id) return;

        getItem(token_id);

        return () => setItem(null);
    }, [token_id])

    const handleChoose = () => {
        console.log({ type })
        if (type === 'absorb') {
            const _state = state ? JSON.parse(JSON.stringify(state)) : {};
            if (Number(token_type_id) === Number(TokenType.Persona)) {
                Object.assign(_state, { absorber: item });
            } else {
                Object.assign(_state, { target: item });
            }
            navigate(`/wallet/absorb`, { state: _state })
        } else if (type === 'drawchains') {
            const drawchainState = localStorage.getItem('drawchain-state');
            const _state = drawchainState ? JSON.parse(drawchainState) : {};
            if (Number(token_type_id) === Number(TokenType.Persona)) {
                Object.assign(_state, { persona: item });
            }
            localStorage.setItem('drawchain-state',JSON.stringify(_state));
            navigate(`/squares/${square_id}/draw-chains/${_state.draw_chain_id}/compare/${item.token_id}`)
        } else {
            navigate(`/wallet/send/address`, { state: { token: item, token_type_id, token_id } })
        }
    }

    const AdditionalBottom = ({ smallBtn }) => (
        <button className={`btn-custom-active ${smallBtn ? '' : 'btn-big'} w-100`} onClick={handleChoose}>
            <span>
                {translations.formatString(buttonlist.chooseBtn)}
            </span>
        </button>
    )

    return (

        <AppWrapper
            title={wallet.headerTitle}
            additionalBottom={
                <div className='d-block d-lg-none'>
                    {AdditionalBottom({})}
                </div>}
        >
            {
                item ?
                    <ItemDetail
                        item={item}
                        animaAmount={animaAmount}
                        additionalBottom={
                            item && AdditionalBottom({ smallBtn: true })
                        }
                    /> : <OnLoading />
            }
            {/* <Footer /> */}
        </AppWrapper>
    )
}

export default WalletSendToken