import React from 'react';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { strings, translations } from "../../services/localization";
import { Btn } from './Button';
import { AdsTag } from './AdsTag';

var test = "#ANICANA, #X to Earn, #Web3.0\r\nANICANA「X to Earn」のPV第一弾です。\r\n\r\n©アニメートアーク／アニカナジャパン製作委員会";
function TopNewsPC(props) {
    const navigate = useNavigate();
    const buttonlist = translations.buttonlist;
    let { topNews, topImage } = props;
    const isAd = !(topNews && 'news_categories' in topNews);

    const seeDetail = () => {
        if (topNews !== undefined) {
            if (isAd && topNews?.link) {
                window.location.assign(topNews?.link)
            } else {
                navigate(`/news_detail/${topNews?.id}`)
            }
        }
    }

    return (
        <div className={`row pt-3 d-none d-lg-flex position-relative ${props.className}`} style={Object.assign({ marginBottom: 50 }, props.style)}>
            <div className='col-12 col-lg-5'>
                {topNews !== undefined &&
                    topImage?.split('.').pop() === 'mp4'
                    ?
                    <>
                        <video className={'iframe-video lex-center w-100 pointer'} src={topImage} autoPlay muted playsInline style={props.square ? { height: 270 } : {}}></video>
                    </>
                    :
                    <div className='flex-center w-100 pointer' style={{ height: props.square ? 270 : 320, borderRadius: 10, backgroundImage: `url('${topImage || topNews?.thumbnail_image_url}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                }
            </div>
            <div className='col-12 col-lg-7'>
                <div className='w-100 h-100 d-flex flex-column align-items-start' style={{ minWidth: 0 }}>
                    <div className='w-75 py-lg-3' style={{ minHeight: '68%' }}>
                        <div className='d-flex gap-3'>
                            { isAd ? null : <small className='fw-600 f-regular text-start text-gradient'>{moment(topNews?.published).format('YYYY-MM-DD H:mm')}</small>}
                            { isAd ? <AdsTag /> : null}
                        </div>
                        <h4 className='fw-600 jp-fw-400 f-regular text-start my-2' style={{ fontSize: '25px', fontFamily: 'Kardust' }}>{topNews?.title}</h4>
                        <div className='fw-500 f-regular text-justify mb-3' style={{ fontSize: '16px', fontFamily: 'Kardust', letterSpacing: '0.03em', wordWrap: 'break-word' }}>
                            {/* {createMarkup((translations._language === 'jp' ? topNews?.sub_title : topNews?.sub_title_en) || topNews?.sub_title_en)} */}
                            {/* <span className='font-inherit pre-wrap'>{test}</span> */}
                            <span className='font-inherit pre-wrap'>{topNews?.sub_title}</span>
                            {/* <span className='font-inherit' dangerouslySetInnerHTML={{ __html: isJP() ? topNews?.sub_title : topNews?.sub_title_en }}></span> */}
                        </div>
                    </div>
                    <Btn intent="none" className='btn btn-see-details' onClick={() => seeDetail()}>
                        {strings.formatString(buttonlist.seeDetailBtn)}
                    </Btn>
                </div>
            </div>
        </div>
    )
}

export default TopNewsPC
