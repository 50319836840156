/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive'
import { useParams } from "react-router-dom";
import moment from 'moment';

import { API_DOMAIN } from "../../config";
import "../../style/newdetail.css";
import "../../style/ProductDetail.scss";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { ArcanaNoImgDetailIcon } from "../../components/common/Icon";
import { OnLoading } from "../../components/common/OnLoading";
import { appContext } from "../../context/appContext";
import { translations } from "../../services/localization";
import { extractTextFromTags, parseToExecutableHTML } from "../../components/common/commonFnc";
import ReactStyleBlocker from "../../components/common/ReactStyleBlocker";
import { getData } from "../../services/fetch";

function PersonaCollectionDetail() {
  const { id } = useParams();

  const [collectionDetail, setCollectionDetail] = useState();
  const [loading, setLoading] = useState(true);
  const { language, timeZone } = useContext(appContext)
  const content = translations.tokenList;

  const fetchPersonaDetailData = async () => {
    const url = API_DOMAIN + "personas/" + `${id}`;
    const response = await getData(url)
    setCollectionDetail(response.data.data);
    setLoading(false);
  };

  const isPh = useMediaQuery({ maxWidth: 576 })
  const isPC = useMediaQuery({ minWidth: 992 })

  // const topImage = !isPh && collectionDetail.image_url ? collectionDetail.image_url : collectionDetail.image_url
  useEffect(() => {
    if (language && timeZone) {
      fetchPersonaDetailData();
    }
  }, [language, timeZone]);

  const metaTitle = "ペルソナ｜ANICANA θυρα（アニカナテュラー）";
  const metaDescription =
    "ANICANA θυρα（アニカナテュラー）のアルカナ一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています";

  return (
    <AppWrapper
      title={content.Persona}
      noPadding={true}
      isNoSpaceAround metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      {loading
        ? <OnLoading />
        : 
        <ReactStyleBlocker>
          <div dangerouslySetInnerHTML={{ __html: parseToExecutableHTML(collectionDetail?.content) }}></div>
        </ReactStyleBlocker>
      }
    </AppWrapper>
  );
}

export default PersonaCollectionDetail;
