import React, { useEffect } from 'react'
import { REACT_APP_LEVIAS_IDMS_URL, REACT_APP_R_SERVER } from '../../config'
import { useParams } from 'react-router-dom'

const LeviasLogin = () => {
    // callId/:signText
    const { callId, signText } = useParams();
    useEffect(() => {
        if (callId && signText) {
            window.location.href = `${REACT_APP_LEVIAS_IDMS_URL}login/idms/${callId}/${signText}${window.location.search}${!!window.location.search ? '&' : '?'}r_server=${REACT_APP_R_SERVER}`
        }
    }, [callId, signText])
    return (
        <div></div>
    )
}

export default LeviasLogin