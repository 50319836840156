import { translations } from "../../services/localization"

export const AdsTag = ({ atCorner = false }) => {
    const { advertisement } = translations.adsTag
    return (
        <div
            className={`d-flex align-items-center ${atCorner ? 'px-3 py-2' : 'px-3'}`}
            style={{
                background: '#BF96FF',
                borderRadius: atCorner ? '12px 0 0 0' : 15,
            }}>
            <p
                className={`fs-${atCorner ? 15 : 12 } text-capitalize`}
                style={{
                    background: 'linear-gradient(192deg, #4B225C 0%, #19162D 23.44%, #19162D 72.92%, #153059 100%)',
                    fontWeight: '500',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    margin:atCorner ? 0 : '0 0 2px 0'
                }}>{advertisement}</p>
        </div>
    )
}