import React, { useState } from 'react'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { translations } from '../../services/localization';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderStatus, orderCurrency } from '../../components/common/enum';
import { BrowserView, MobileView } from 'react-device-detect';
import { WalletPageTitle } from '../../components/common/Title';
import { AnimaIcon, BackGradientIcon, YenIcon } from '../../components/common/Icon';
import { SelectBox } from '../../components/common/Form';
import { OfferAdditionBtn } from './Offer';
import { useSelector } from 'react-redux';

const OfferChooseCurrency = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { token, type } = location.state || {};
    const content = translations.offer;

    const [currency, setCurrency] = useState(undefined);
    const selectedItems = useSelector(state => state.myArcana.selectedItems);

    const handleSelectCurrency = () => {
        if ((token && type && currency) || (selectedItems.length > 0 && type && currency)) {
            navigate(`/offer/${type === OrderStatus.Ask ? 'ask' : 'bid'}/price`, { state: { token, type, currency } })
        } else {
            // navigate('/')
        }
    }

    return (
        <AppWrapper sidebarWrapperDivClassName={'p-t-8'} title={translations.formatString(type === OrderStatus.Ask ? content.AskOffer : content.BidOffer)}
            disableFooter
            additionalBottom={
                <MobileView>
                    <OfferAdditionBtn onCancel={() => navigate(-1)} onSubmit={handleSelectCurrency} submitEnabled={currency} />
                </MobileView>
            }
        >
            <div className='px-4'>
                <BackGradientIcon onClick={() => navigate(-1)} className="position-absolute" style={{ left: 22, top: 13 }} />
                <div className='d-flex flex-column align-items-center'>
                    <WalletPageTitle className='text-white p-x-16' style={{ lineHeight: 32.5 }} underlineClassName='w-100' title={translations.formatString(content.selectPaymentCurrency)} hasUnderline />
                    <div className='w-100 d-flex flex-column justify-content-center' style={{ minHeight: 300, maxWidth: 320 }}>
                        <div className='d-flex align-items-center mb-3 p-3 fs-20'>
                            <input
                                onChange={e => setCurrency(Number(e.target.value))}
                                className="form-check-input radio mt-0"
                                type="radio" value={orderCurrency.ANIMA}
                                checked={orderCurrency.ANIMA === currency}
                                name="currency"
                            />
                            <div className='currency-symbol-container mx-4'>
                                <AnimaIcon className='m-r--2 anima-icon' />
                            </div>
                            {translations.formatString(translations.offer.animaPayment)}
                        </div>
                        <div className='d-flex align-items-center mb-3 p-3 fs-20'>
                            <input
                                onChange={e => setCurrency(Number(e.target.value))}
                                className="form-check-input radio mt-0"
                                type="radio" value={orderCurrency.YEN}
                                checked={orderCurrency.YEN === currency}
                                name="currency"
                            />
                            <div className='currency-symbol-container mx-4'>
                                <YenIcon className="yen-icon " />
                            </div>
                            {translations.formatString(translations.offer.japaneseYenPayment)}
                        </div>
                    </div>
                    <BrowserView>
                        <OfferAdditionBtn onCancel={() => navigate(-1)} onSubmit={handleSelectCurrency} submitEnabled={currency} />
                    </BrowserView>
                </div>
            </div>
        </AppWrapper>
    )
}

export default OfferChooseCurrency