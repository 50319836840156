import { useEffect, useRef, useState } from "react"
import { MenuSearchBox } from "../../components/common/Form"
import { WalletTitle } from "../../components/common/Title"
import AppWrapper from "../../components/wrappers/AppWrapper"
import { translations } from "../../services/localization"
import { useMediaQuery } from "react-responsive"
import { OnLoading } from "../../components/common/OnLoading"
import { ShardCard, TransactionItem } from "../../components/common/Item"
import { useIntersectionWithLastRef } from "../../hooks/useIntersectionWithLastRef"
import { ArrowRightIcon, DoubleRightArrowIcon, FooterPopupBackground, SelectedIcon } from "../../components/common/Icon"
import { Btn } from "../../components/common/Button"
import { Link, useNavigate } from "react-router-dom"
import { REACT_APP_SHARD_CONTRACT_ADDRESS } from "../../config"

const Shard = () => {
    const content = translations.walletShard

    const isSmPh = useMediaQuery({ maxWidth: 452 });
    const isLgPh = useMediaQuery({ maxWidth: 576 });
    const navigate = useNavigate()


    const [search, setSearch] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [loading, setLoading] = useState(false)
    const searchInputRef = useRef();
    const [toggleSelect, setToggleSelect] = useState(false)
    const [selectValue, setSelectValue] = useState([])


    const toggleOpenSearch = () => setOpenSearch(openSearch => !openSearch);

    const handleClickBack = () => {
        setSearch('');
        toggleOpenSearch();
    }

    const handleSearchClear = () => {
        searchInputRef.current.value = "";
        setSearch("")
        searchInputRef.current.focus();
    }

    useEffect(() => {
        if (openSearch && searchInputRef.current) {
            if (!searchInputRef.current.value && search) {
                searchInputRef.current.value = search;
            }
        }
    }, [openSearch])

    const selectToggle = () => {
        setToggleSelect(prev => !prev)
    }

    const handleCard = (id) => {
        if (toggleSelect) {
            return
        }

        navigate('/wallet/shards/' + id)
    }

    const handleSelect = (e) => {
        setSelectValue(prev => ([...prev, e.target.value]));
    }

    // const { ref } = useIntersectionWithLastRef(paginationLoading, hasMore,
    //     () => console.log("fetch more"))

    return (
        <AppWrapper title={translations.formatString(content.headerTitle)}
            additionalBottom={<SelectPopup onClick={() => navigate('/wallet/shards/multiple/address')} toggleSelect={toggleSelect} disabled={selectValue.length > 0 ? false : true} />}
        >
            <div className="d-flex align-items-center justify-content-between">
                <WalletTitle title={content.mainTitle} total={1} className="m-0" />

                <button className="select-btn" onClick={selectToggle}>
                    {toggleSelect ? 'Cancel' : 'Select'}
                </button>

            </div>
            <div className='my-4'>
                <form onSubmit={() => { console.log("submit") }}>
                    <MenuSearchBox ref={searchInputRef} inputText={searchInputRef.current ? searchInputRef.current.value : ""} onClose={handleClickBack} onClear={handleSearchClear} />
                </form>
            </div>

            {false ? <OnLoading /> : (<div className='row'>
                {
                    // [...Array(5)].map((item, index) =>
                        <div className='col-12 col-md-6 col-xl-4'
                            style={{ marginBottom: 20 }}
                        // ref={[...Array(5)].length === index + 1 ? ref : null}
                        >
                            <div onClick={() => handleCard(REACT_APP_SHARD_CONTRACT_ADDRESS)}>
                                <ShardCard handleSelect={handleSelect} toggleSelect={toggleSelect}  id={REACT_APP_SHARD_CONTRACT_ADDRESS} number={"1000"} />
                            </div>
                        </div>
                    // )
                }
                {
                    false && (
                        <OnLoading
                            pRelative
                            noLabel
                            marginAuto
                            className={"m-0"}
                        />
                    )
                }
            </div>)}
            {/* <SelectPopup /> */}
        </AppWrapper>
    )
}

export default Shard

const SelectPopup = ({ toggleSelect, disabled, onClick }) => {
    return (
        <>
            {
                toggleSelect && (
                    <div className="shard-select-popup">
                        <div className="d-flex align-items-end gap-3">
                            <Btn onClick={onClick} disabled={disabled} className="w-100" style={{ background: "#5AA06E", height: 40 }} divClassName="d-flex align-items-center gap-2 fs-20">next <DoubleRightArrowIcon /></Btn>
                            <div>
                                <div className="item-limit text-nowrap mb-2 me-2">Up to 20 items.</div>
                                <div className="item-number">10/20</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}