import { REACT_APP_ANIMA_CONTRACT_ADDRESS, REACT_APP_ARCANA_CONTRACT_ADDRESS, REACT_APP_EGG_CONTRACT_ADDRESS, REACT_APP_PERSONA_CONTRACT_ADDRESS, REACT_APP_TOKEN_CONTRACT_ADDRESS, REACT_APP_RAM_CONTRACT_ADDRESS, REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS, REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS, REACT_APP_SHARD_CONTRACT_ADDRESS } from "../../config";
import arcana_abi from '../../abi/arcana_abi.json';
import persona_abi from '../../abi/persona_abi.json';
import anima_abi from '../../abi/anima_abi.json';
import egg_abi from '../../abi/egg_abi.json';
import arcana_gen_info_abi from '../../abi/ArcanaGeneratorInfo.json';
import drawchain_abi from '../../abi/drawchain_abi.json';
import square_supplement_abi from '../../abi/square_supplement_abi.json';
import { TokenNoImageIcon, RamcoinIcon } from "../../components/common/Icon";
import { isJP } from "./commonFnc";
import { translations } from "../../services/localization";

export const TokenType = Object.freeze({
    Arcana: 1,
    Anima: 2,
    Persona: 3,
    Token: 4,
    Egg: 5,
    ArcanaGeneratorInfo: 6,
    DrawChain: 7,
    Square: 8,
    getEnumName: (value) => getEnumName(TokenType, Number(value)),
    getABI: (id) => getABI(id),
    getContractAddress: (id) => getContractAddress(id),
    getRouteName: (id) => getRouteName(id),
    getNameByContractAddress: (address) => getNameByContractAddress(address)
})

export const TransactionStatus = Object.freeze({
    Send: 1,
    Receive: 2
})

export const LevicaTransactionStatus = Object.freeze({
    pending: 'pending',
    payment_completed: 'payment_completed',
    transaction_completed: 'transaction_completed',
    transaction_fail: 'transaction_fail',
    transaction_canceled: 'transaction_canceled',
})

export const OrderStatus = Object.freeze({
    Ask: "sell",
    Bid: "buy",
    getEnumName: (value) => getEnumName(OrderStatus, value),
})

export const Elements = [
    'Eternal Tree',
    'Crimson Flame',
    'Adamantine Rock',
    'Peerless Steel ',
    'Heavenly Dew',
    'Golden Light',
    'Unfathomable Abyss',
];

export const getEnumName = (refractiveIndex, value) => {
    var key;
    Object.keys(refractiveIndex).some(function (k) {
        if (refractiveIndex[k] === value) {
            key = k;
            return true;
        }
    });
    return key || '';
}

export const getRouteName = id => {
    switch (Number(id)) {
        case TokenType.Arcana: return 'arcana';
        case TokenType.Persona: return 'persona';
        default: return 'token';
    }
}

export const getContractAddress = id => {
    switch (Number(id)) {
        case TokenType.Arcana: return REACT_APP_ARCANA_CONTRACT_ADDRESS;
        case TokenType.Persona: return REACT_APP_PERSONA_CONTRACT_ADDRESS;
        case TokenType.Anima: return REACT_APP_ANIMA_CONTRACT_ADDRESS;
        case TokenType.Token: return REACT_APP_TOKEN_CONTRACT_ADDRESS;
        case TokenType.Egg: return REACT_APP_EGG_CONTRACT_ADDRESS;
        case TokenType.DrawChain: return REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS;
        case TokenType.Square: return REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS;
        case TokenType.Shard: return REACT_APP_SHARD_CONTRACT_ADDRESS;
        default: return undefined;
    }
}

export const getNameByContractAddress = address => {
    switch ((address || '').toLowerCase()) {
        case (REACT_APP_ARCANA_CONTRACT_ADDRESS || '').toLowerCase(): return "Arcana";
        case (REACT_APP_PERSONA_CONTRACT_ADDRESS || '').toLowerCase(): return "Persona";
        case (REACT_APP_ANIMA_CONTRACT_ADDRESS || '').toLowerCase(): return "Anima";
        case (REACT_APP_EGG_CONTRACT_ADDRESS || '').toLowerCase(): return "Egg";
        case (REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS || '').toLowerCase(): return "DrawChain";
        case (REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS || '').toLowerCase(): return "Square";
        case (REACT_APP_SHARD_CONTRACT_ADDRESS || '').toLocaleLowerCase(): return "Shard";
        default: return undefined;
    }
}

export const TokenTypeByName = {
    Arcana: 'Arcana',
    Persona: 'Persona',
    Anima: 'Anima',
    Other: 'Other',
}

export const languageEnum = {
    en: 'English',
    jp: 'Japanese'
}

export const getTypeByContractAddress = address => {
    switch ((address || '').toLowerCase()) {
        case (REACT_APP_ARCANA_CONTRACT_ADDRESS || '').toLowerCase(): return TokenType.Arcana;
        case (REACT_APP_PERSONA_CONTRACT_ADDRESS || '').toLowerCase(): return TokenType.Persona;
        case (REACT_APP_ANIMA_CONTRACT_ADDRESS || '').toLowerCase(): return TokenType.Anima;
        case (REACT_APP_EGG_CONTRACT_ADDRESS || '').toLowerCase(): return TokenType.Egg;
        case (REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS || '').toLowerCase(): return TokenType.DrawChain;
        case (REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS || '').toLowerCase(): return TokenType.Square;
        default: return undefined;
    }
}

export const getABI = id => {
    switch (Number(id)) {
        case TokenType.Arcana: return arcana_abi;
        case TokenType.Persona: return persona_abi;
        case TokenType.Anima: return anima_abi;
        case TokenType.Token: return anima_abi;
        case TokenType.Egg: return egg_abi;
        case TokenType.ArcanaGeneratorInfo: return arcana_gen_info_abi;
        case TokenType.DrawChain: return drawchain_abi;
        case TokenType.Square: return square_supplement_abi;
        default: return undefined;
    }
}

export const SearchType = {
    Checkbox: 1,
    Range: 2,
    Dropdown: 3,
    Text: 4,
    Date: 5,
    DateRange: 6
}

export const getTokenImageByContractAddress = address => {
    switch ((address || '').toLowerCase()) {
        case (REACT_APP_ARCANA_CONTRACT_ADDRESS || '').toLowerCase(): return <TokenNoImageIcon />;
        case (REACT_APP_PERSONA_CONTRACT_ADDRESS || '').toLowerCase(): return <TokenNoImageIcon />;
        case (REACT_APP_RAM_CONTRACT_ADDRESS || '').toLowerCase(): return <RamcoinIcon />;
        case (REACT_APP_EGG_CONTRACT_ADDRESS || '').toLowerCase(): return <TokenNoImageIcon />;
        case (REACT_APP_DRAWCHAIN_CONTRACT_ADDRESS || '').toLowerCase(): return <TokenNoImageIcon />;
        case (REACT_APP_SQUARE_SUPPLEMENT_CONTRACT_ADDRESS || '').toLowerCase(): return <TokenNoImageIcon />;
        default: return <TokenNoImageIcon />;
    }
}

export const paymentCurrency = Object.freeze({
    Anima: 'anima',
    Yen: 'yen',
    getSymbol: (currency) => getCurrencySymbol(currency)
})

export const orderCurrency = Object.freeze({
    YEN: 1,
    ANIMA: 2,
})

export const SortTypeEnum = Object.freeze({
    HIGHEST_PURCHASE: 'highest_purchase',
    LOWEST_PURCHASE: 'lowest_purchase',
    RECENTLY_CREATED: 'recently_created',
    RECENTLY_RECEIVED: 'recently_received',
    RECENTLY_LISTED: 'recently_listed',
    PRICE_LOW_TO_HIGH: 'price_low_to_high',
    PRICE_HIGH_TO_LOW: 'Price_high_to_low',
    NAME: 'name',
    DEF_POINTS: 'def_points',
    DESC: 'DESC',
    ASC: 'ASC',
    ANIMA_HIGHEST_PURCHASE: 'anima_highest_purchase',
    ANIMA_LOWEST_PURCHASE: 'anima_lowest_purchase',
    YEN_HIGHEST_PURCHASE: 'yen_highest_purchase',
    YEN_LOWEST_PURCHASE: 'yen_lowest_purchase',
})

const sort = translations.sort;
export const TokenSortTypes = [
    { label: translations.formatString(sort.Newest), value: SortTypeEnum.DESC },
    { label: translations.formatString(sort.Oldest), value: SortTypeEnum.ASC },
    { label: translations.formatString(sort.Yen_Highiest_Purchase), value: SortTypeEnum.YEN_HIGHEST_PURCHASE },
    { label: translations.formatString(sort.Yen_Lowest_Purchase), value: SortTypeEnum.YEN_LOWEST_PURCHASE },
    { label: translations.formatString(sort.Anima_Highiest_Purchase), value: SortTypeEnum.ANIMA_HIGHEST_PURCHASE },
    { label: translations.formatString(sort.Anima_Lowest_Purchase), value: SortTypeEnum.ANIMA_LOWEST_PURCHASE },
];

export const getCurrencySymbol = currency => {
    switch (currency) {
        case orderCurrency.ANIMA:
        case paymentCurrency.Anima: return translations.formatString(translations.common.animaSymbol);
        case orderCurrency.YEN:
        case paymentCurrency.Yen: return translations.formatString(translations.common.yenSymbol);
        default: return undefined;
    }
}

export const ThirdPartyProvider = Object.freeze({
    Octillion: "octillion",
    getEnumName: (value) => getEnumName(ThirdPartyProvider, value),
})