import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Item, { CollectionItem, SampleItem } from "../../components/common/Item";
import AppWrapper from "../../components/wrappers/AppWrapper";
import { translations } from "../../services/localization";
import { OnLoading } from "../../components/common/OnLoading";
import { ReactComponent as FiltersSVG } from "../../assets/icons/token_filter.svg";
import { ReactComponent as SortOrderSVG } from "../../assets/icons/sort-solid.svg";
import { appContext } from "../../context/appContext";
import { handleSearch } from "../../components/common/commonFnc";
import Sort from "./Sort";
import {
  isIOS,
  isChrome,
  isMobile,
  isMobileOnly,
  isMobileSafari,
} from "react-device-detect";
import { getData } from "../../services/fetch";
import { TokenType } from "../../components/common/enum";
import { useDidUpdateEffect } from "../../services/hook";
import { setTokenData, setTokenPage } from "../../store/Position";
import InfiniteScrollContainer from "../../components/common/InfiniteScrollContainer";
import Filter from "./Filter";

const TokenList = ({ isCollection }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    search,
    filter,
    updateFilter,
    filterCount,
    filterTotal,
    sort,
    setSort,
  } = useContext(appContext);
  const { token_type_id, collection_id } = useParams();
  const content = translations.tokenList;
  const commonlist = translations.common;

  const [collectionItems, setCollectionItems] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageId, setPageId] = useState('');
  const [page, setPage] = useState(1);
  const [filterBadgeNo, setFilterBadgeNo] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const [itemsLimit, setItemsLimit] = useState(null);
  const isLgPh = useMediaQuery({ maxWidth: 576 });
  const [total, setTotal] = useState(-1)

  useEffect(() => {
    if (Number(token_type_id) === Number(TokenType.Arcana)) {
      setPageId("Arcana");
    } else if (Number(token_type_id) === Number(TokenType.Persona)) {
      setPageId("Persona");
    }
    updateFilter("token_type_id", token_type_id);
  }, [token_type_id]);

  useDidUpdateEffect(() => {
    setLoading(true);
    setCollectionItems([]);
    setItems([]);
    setPage(1);
    // getItem(search, filter, sort, 1, collection_id);
    setFetchCount(count => count + 1);
  }, [token_type_id, collection_id]);

  useEffect(() => {
    if (filter && filter.token_type_id) {
      if (page === 1) {
        setFetchCount(count => count + 1)
      } else {
        setPage(1);
      }
    }
  }, [filterCount, search, sort]);

  useDidUpdateEffect(() => {
    if (filter && filter.token_type_id && itemsLimit) {
      setFetchCount(count => count + 1)
    }
  }, [page, itemsLimit]);

  useDidUpdateEffect(() => {
    getItem(search, filter, sort, page, collection_id)
  }, [fetchCount])

  const getItem = async (search, filter, sort, page, collection_id) => {
    if (itemsLimit) {
      if (page === 1) {
        setLoading(true);
        setCollectionItems([]);
        setItems([]);
      }
      if (filter.is_sell == false) {
        delete filter.is_sell;
      }
      let tokens = [],
        has_next_page = false,
        total = 0,
        personaDetail = null;
      if (isCollection && Number(token_type_id) === Number(TokenType.Persona)) {
        const response = await getData(`personas?page=${page}`);
        tokens = response.data.data.data;
        has_next_page = !!response.data.data.next_page_url;
        total = response.total;
      } else {
        const _data = await getData(`personas/${collection_id}`);
        personaDetail = _data.data.data;
        
        const response = await handleSearch(
          search,
          filter,
          sort,
          "",
          page,
          false,
          itemsLimit,
          collection_id
        );
        tokens = response.tokens;
        has_next_page = response.has_next_page;
        total = response.total;
      }
      if (!tokens || tokens.length === 0) {
        setCollectionItems([]);
        const _data = personaDetail !== null && personaDetail.persona_detail_card === 'show' ? [personaDetail] : [];
        setItems(_data);
      } else {
        if (isCollection) {
          setCollectionItems(tokens || []);
        } else {
          const _data = personaDetail !== null && personaDetail.persona_detail_card === 'show' ? [personaDetail, ...tokens] : tokens
          setItems(_data || []);
        }
      }
      setHasMore(has_next_page);
      setTotal(total)
      setFilterBadgeNo(filterTotal);
      if (isMobile) {
        setFilterOpen(false);
        setSortOpen(false);
      }
      setLoading(false);
    }
  }

  const toggleFilter = () => {
    setFilterOpen((open) => !open);
    setSortOpen(false);
  };
  const toggleSort = () => {
    setSortOpen((open) => !open);
    setFilterOpen(false);
  };
  const isPh = useMediaQuery({ maxWidth: 576 });

  const closeAll = () => {
    setFilterOpen(false);
    setSortOpen(false);
  };

  const goToPersonaList = (id) => {
    navigate(`/tokens/${TokenType.Persona}/collection/${id}`);
  };

  const title =
    Number(token_type_id) === Number(TokenType.Arcana)
      ? translations.formatString(content.headerTitle)
      : translations.formatString(content.Persona);
  const metaTitle =
    Number(token_type_id) === Number(TokenType.Persona)
      ? "ペルソナ｜ANICANA θυρα（アニカナテュラー）"
      : "アルカナ｜ANICANA θυρα（アニカナテュラー）";
  const metaDescription =
    "ANICANA θυρα（アニカナテュラー）のアルカナ一覧です｜ANICANA θυρα（アニカナテュラー）は、ブロックチェーンを用いた価値返還インフラ「アニカナ」にまつわる、最新ニュース、コンテンツ、サービス、ゲーム、NFT、API情報などを提供しています";

  if (filterOpen && window.innerWidth <= 992)
    return (
      <AppWrapper
        isFilter={false}
        noLayout
        disableSidebar
        disableHeader
        mainbodyClassName={"hasFixedBottom sidebarBrandFixed"}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <Filter onRequestClose={closeAll} />
      </AppWrapper>
    );

  if (sortOpen && window.innerWidth <= 992) {
    return (
      <AppWrapper
        isSort={false}
        noLayout
        disableSidebar
        disableHeader
        mainbodyClassName={"hasFixedBottom sidebarBrandFixed"}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <Sort onRequestClose={closeAll} />
      </AppWrapper>
    );
  }
  return (
    <AppWrapper
      isFilter={filterOpen}
      isSort={sortOpen}
      title={title}
      enableMobileSearch
      onSearch={getItem}
      pageid={pageId}
      disableSideContentScroll
      sidebarWrapperDivClassName={"p-t-8"}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
    >
      <div
        className={"d-flex flex-row justify-content-between align-items-center"}
        style={{ marginBottom: 21 }}
      >
        <div className="d-flex justify-content-center">
          {/* filter btn at tokens/id */}
          <button
            className="btn-custom-filters filter-btn d-flex flex-row flex-center w-100 me-2 pt-1"
            onClick={toggleFilter}
          >
            <div className="px-3 d-flex align-items-center">
              <FiltersSVG
                style={{ marginTop: !isPh ? 3 : 0 }}
                className="purple-icon"
              />
              {!isPh && (
                <span className="anikana-filters ms-1">
                  {translations.formatString(content.filter)}
                </span>
              )}
              {!!filterBadgeNo && (
                <span className="badge-custom-2">{filterBadgeNo}</span>
              )}
            </div>
          </button>

          {/* sort btn at tokens/id */}
          <button
            className="px-3 btn-custom-filters filter-btn w-100 ms-2 pt-2"
            onClick={toggleSort}
          >
            <div className="px-3 d-flex align-items-center">
              <SortOrderSVG></SortOrderSVG>
              {!isPh && (
                <span className="anikana-filters ms-1">
                  {translations.formatString(content.sort)}
                </span>
              )}
            </div>
          </button>
        </div>
      </div>

      {!loading ? (
        <> 
          {
            items.length < 0 ?
              <div className="d-flex flex-center w-100 mt-5">
                <p>
                  {Number(token_type_id) === Number(TokenType.Arcana)
                    ? translations.formatString(commonlist.noSearchResult)
                    : commonlist.noPersonaResult}
                </p>
              </div>
            :
          <div
            className={`overflow-scroll overflow-hidden-x ${isChrome ? "p-b-100" : "p-b-85"
              } p-b-lg-0`}
            style={{
              height: `calc(100vh - ${isMobileSafari || (isMobile && isIOS)
                ? "300"
                : isMobileOnly
                  ? "250"
                  : "160"
                }px`,
            }}
          >
            <InfiniteScrollContainer
              onChangePage={setPage}
              hasMore={hasMore}
              newItems={isCollection ? collectionItems : items}
              itemSize={isLgPh ? 210 : 226}
              leftSpace={0}
              rightSpace={isLgPh ? 0 : 16}
              limit={itemsLimit}
              setLimit={setItemsLimit}
              total={total}
              renderedContainerClassName='row token-list m-0'
              renderedComponent={(item, index, ref) =>
                isCollection ?
                  <CollectionItem
                    ref={ref}
                    key={`group_item_${index}`}
                    item={item}
                    token={true}
                    containerClassName="mb-0"
                    isunboldheader={true}
                    onClick={() => goToPersonaList(item.id)}
                    token_type_id={token_type_id}
                  /> : 
                  item.id !== undefined && item.id !== null ?
                  <SampleItem 
                    ref={ref} 
                    key={`token_item_${index}`}
                    item={item}
                    token={true}
                    containerClassName="mb-0"
                    isunboldheader={true}
                    btnText={content.seeDetail}
                  />
                  :
                  <Item
                    ref={ref}
                    key={`token_item_${index}`}
                    item={item}
                    token={true}
                    containerClassName="mb-0"
                    isunboldheader={true}
                  />


              }
            />
          </div>
          }
        </>
      ) : (
        <OnLoading />
      )}
    </AppWrapper>
  );
};

export default TokenList;
