import { useState } from "react"
import { TextBox } from "../../components/common/Form"
import { WalletPageTitle } from "../../components/common/Title"
import AppWrapper from "../../components/wrappers/AppWrapper"
import { strings, translations } from "../../services/localization"
import { Btn } from "../../components/common/Button"
import { useNavigate } from "react-router-dom"
import { SwalSettings } from "../../components/common/Swal"
import { useMediaQuery } from "react-responsive"
import { formatNumber, isJP, swalText } from "../../components/common/commonFnc"
import { BottomModal } from "../../components/common/BottomModal"
import { Detail } from "../wallet/Wallet"

const ShardSendAddress = () => {
    const content = translations.walletShard
    const [to_address, setTo_address] = useState('')
    const [amount, setAmount] = useState('')
    const [showBottomModal, setShowBottomModal] = useState(false)
    const navigate = useNavigate()
    const language = translations._language;
    const buttonlist = translations.buttonlist;

    const isPC = useMediaQuery({ minWidth: 992 })

    const showModalHandler = () => {
        if (to_address || amount) {
            isPC ? onCancelEvent() : setShowBottomModal(true)
        } else {
            navigate('/wallet');
        }
    }
    const onCancelEvent = () => {
        SwalSettings.fire({
            text: strings.formatString(content.Are_you_sure_you_want_to_cancel_sending),
            confirmButtonText: swalText(buttonlist.yesBtn),
            cancelButtonText: swalText(buttonlist.noBtn),
        }).then((result) => {
            if (result.isConfirmed) {
                // navigate(-2);
            }
        });
    }

    const onCloseBottomModal = () => {
        setShowBottomModal(false)
    }

    return (
        <>
            {
                <BottomModal
                    onCloseHandler={onCloseBottomModal}
                    onShow={showBottomModal}
                    label={translations.formatString(content.Are_you_sure_you_want_to_cancel_sending)}
                    cancelBtnLabel={translations.formatString(buttonlist.yesBtn)}
                    BackBtnLabel={translations.formatString(buttonlist.noBtn)}
                    navigation='/wallet/shards/'
                />
            }
            <AppWrapper title={translations.formatString(content.headerTitle)}>
                <WalletPageTitle className='text-white p-x-16' title={translations.formatString(content.sendShard)} hasBackIcon />
                <div className="d-flex flex-column align-items-center">
                    <div className='text-center mx-auto' style={{ marginTop: 24, marginBottom: 32, maxWidth: 310 }}>
                        <span className='text-break fw-500 f-nototsan' style={{ fontSize: '18px' }}>{translations.formatString(content.Please_confirm_the_transaction_details_below)}</span>
                    </div>
                    {
                        true ? (
                            <div className='d-flex flex-column mb-3 w-100' style={{ maxWidth: 450 }}>
                                <span className={`fw-400 text-white text-start ${isJP() ? 'f-notosan' : 'f-regular'}`}>Shard IDs / 3</span>
                                <div className="w-100 text-box-container mt-3" style={{ maxWidth: 450 }}>
                                    <div className='text-box scrollable '>
                                        {
                                            [...Array(3)].map((item, index) => (
                                                <>
                                                    <div key={index}>1234567788901234567788901234567788901234567788901234567788901234567788901234</div>
                                                    <div className="divider"></div>
                                                </>
                                            ))
                                        } </div>
                                </div>
                            </div>
                        )
                            :
                            <Detail nolabelpadding name={translations.formatString(content.labelShardId)} value={"1234567788901234567788901234567788901234567788901234567788901234567788901234"} isShardID />
                    }
                    <div className="w-100" style={{ maxWidth: 450 }}>
                        {
                            true ?
                                <Detail nolabelpadding name={translations.formatString(content.labelAmountTotal)} value={formatNumber(4660)} isShard />
                                :
                                <Detail nolabelpadding name={translations.formatString(content.labelAmount)} value={formatNumber(4660)} isShard />
                        }
                    </div>

                    <Detail nolabelpadding name={translations.formatString(content.labelTo)} value={"8jr547h2we0df55hd8tj49esjero5idw4f344"} isShardID />

                    <div className='flex-row flex-center mt-5 form-wrapper'>
                        <Btn type="button"
                            intent="outline"
                            className={`w-100 me-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                            onClick={showModalHandler}
                        >
                            {translations.formatString(buttonlist.cancelBtn)}
                        </Btn>
                        <Btn type="submit"
                            disabled={false}
                            className={`w-100 ms-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                        >
                            {translations.formatString(buttonlist.submitBtn)}
                        </Btn>
                    </div>


                </div>
            </AppWrapper>  </>
    )
}

export default ShardSendAddress