import React, { useEffect, useState } from 'react'
import AppWrapper from '../../components/wrappers/AppWrapper'
import { strings, translations } from '../../services/localization'
import { FormLabel, TextArea, TextBox } from '../../components/common/Form'
import { ImagePreviewIcon, UploadImageIcon } from '../../components/common/Icon'
import { Btn } from '../../components/common/Button'
import { useNavigate } from 'react-router-dom'

const PersonaIssuance = () => {
    const content = translations.personaIssuance
    const [image, setImage] = useState()
    const navigate = useNavigate()
    return (
        <AppWrapper title={translations.formatString(content.headerTitle)}
            additionalBottom={<Btn divClassName="fs-25" className="mt-4 image-preview-btn d-block d-lg-none w-100" style={{ height: 60 }}>{strings.formatString(content.labelapply)}</Btn>}
        >
            <p className='f-condensed fs-15 header-text'>Applications for PERSONA issuance are accepted at the secondary marketplace Octillion.
                Your application will be approved on the same day at the earliest,
                however it may take several days to complete the review.
                For inquiries, please contact us via <a target='_blank' className='d-none d-lg-block info-link' href='info@octillion.jp'>info@octillion.jp</a>.</p>
            <a target='_blank' className='d-block d-lg-none my-4 info-link text-center' href='info@octillion.jp'>info@octillion.jp</a>
            <div className='persona-issuance-container row gx-5'>
                <div className='col-12 col-lg-6'>
                    <TextBox className='f-regular fs-18 ' containerClassName='mb-3'
                        label={strings.formatString(content.labelPersonaName)} placeholder={strings.formatString(content.labelEnterPersonaName)}
                        // onChange={e => setSeedValue(e.target.value)} value={manaValue}
                        disabled={false} />

                    <TextBox className='f-regular fs-18' containerClassName='mb-3'
                        label={strings.formatString(content.labelCreatorName)} placeholder={strings.formatString(content.labelEnterCreaterName)}
                        // onChange={e => setSeedValue(e.target.value)} value={manaValue}
                        disabled={false} />

                    <TextBox className='f-regular fs-18' containerClassName='mb-3'
                        label={strings.formatString(content.labelQuantity)} placeholder={strings.formatString(content.labelEnterQuantity)}
                        // onChange={e => setSeedValue(e.target.value)} value={manaValue}
                        disabled={false} />

                    <TextBox className='f-regular fs-18' containerClassName='mb-3'
                        label={strings.formatString(content.labelMaxQuantity)} placeholder={strings.formatString(content.labelEnterMaxQuantity)}
                        // onChange={e => setSeedValue(e.target.value)} value={manaValue}
                        disabled={false} />

                    <TextArea className='f-regular fs-18' containerClassName='mb-3'
                        label={strings.formatString(content.labelPersonaDescription)} placeholder={strings.formatString(content.labelEnterPersonaDescription)}
                        // value={manaInfo}
                        inputStyle={{ minHeight: 146 }}
                        disabled={false} />
                </div>
                <div className='col-12 col-lg-5'>


                    <div className="mb-3" >
                        <FormLabel label={strings.formatString(content.labelPersonaImage)} />
                        <div className='d-flex flex-row'>
                            <div className='position-relative w-100'>
                                <label htmlFor='image-input'
                                    className={`form-control auth-form-input-absolute jp-fw-400 pe-5 f-regular fs-18`}>
                                    {strings.formatString(content.labelUploadImage)}
                                    <input
                                        name='image-input'
                                        id='image-input'
                                        type="file"
                                        className="d-none"
                                        onChange={(e) => setImage(e.target.files[0])}
                                    />

                                    <div className='position-absolute flex-center h-100 pointer' style={{ top: 0, right: 15 }} onClick={() => { }}>
                                        <UploadImageIcon />
                                    </div>
                                </label>

                            </div>
                        </div>
                    </div>


                    <ImagePreview label={strings.formatString(content.labelPreview)} image={image} />

                    <Btn className="mt-4 image-preview-btn d-none d-lg-block" onClick={() => navigate('/')}>{strings.formatString(content.labelapply)}</Btn>
                </div>

            </div>
        </AppWrapper>
    )
}

const ImagePreview = ({ label, image }) => {
    const [previewItem, setPreviewItem] = useState()
    const getImgURL = async (file) => {
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setPreviewItem(
                reader.result
            )
        }
        console.log(url)
    };

    useEffect(() => {
        getImgURL(image);
    }, [image])

    return (
        <>
            {
                previewItem ? (
                    <div>
                        <FormLabel label={label} />
                        <div className='image-preview-container'>
                            <img src={previewItem} className='preview-image'/>
                        </div>
                    </div>
                ) : (

                    <div>
                        <FormLabel label={label} />
                        <div className='image-preview-container'>
                            <ImagePreviewIcon />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default PersonaIssuance