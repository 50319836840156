import { useState } from "react"
import { TextBox } from "../../components/common/Form"
import { WalletPageTitle } from "../../components/common/Title"
import AppWrapper from "../../components/wrappers/AppWrapper"
import { strings, translations } from "../../services/localization"
import { Btn } from "../../components/common/Button"
import { useNavigate } from "react-router-dom"
import { SwalSettings } from "../../components/common/Swal"
import { useMediaQuery } from "react-responsive"
import { swalText } from "../../components/common/commonFnc"
import { BottomModal } from "../../components/common/BottomModal"

const ShardSendAddress = () => {
    const content = translations.walletShard
    const [to_address, setTo_address] = useState('')
    const [amount, setAmount] = useState('')
    const [showBottomModal, setShowBottomModal] = useState(false)
    const navigate = useNavigate()
    const language = translations._language;
    const buttonlist = translations.buttonlist;

    const isPC = useMediaQuery({ minWidth: 992 })

    const showModalHandler = () => {
        if (true) {
            isPC ? onCancelEvent() : setShowBottomModal(true)
        } else {
            // navigate('/wallet/shards');
        }
    }
    const onCancelEvent = () => {
        SwalSettings.fire({
            text: strings.formatString(content.Are_you_sure_you_want_to_cancel_sending),
            confirmButtonText: swalText(buttonlist.yesBtn),
            cancelButtonText: swalText(buttonlist.noBtn),
        }).then((result) => {
            if (result.isConfirmed) {
                // navigate(-2);
            }
        });
    }
    const onCloseBottomModal = () => {
        setShowBottomModal(false)
    }

    return (
        <>
            {
                <BottomModal
                    onCloseHandler={onCloseBottomModal}
                    onShow={showBottomModal}
                    label={translations.formatString(content.Are_you_sure_you_want_to_cancel_sending)}
                    cancelBtnLabel={translations.formatString(buttonlist.yesBtn)}
                    BackBtnLabel={translations.formatString(buttonlist.noBtn)}
                    navigation='/wallet/shards/'
                />
            }
            <AppWrapper title={translations.formatString(content.headerTitle)}>
                <WalletPageTitle className='text-white p-x-16' title={translations.formatString(content.sendShard)} hasBackIcon />
                <div className="d-flex flex-column align-items-center">
                    <div className="w-100" style={{ maxWidth: 450 }}>
                        <div className='text-box mt-4'>1234567788901234567788901234567788901234567788901234567788901234567788901234</div>
                    </div>
                    <div className='fs-35 f-condensed text-uppercase text-center m-t-15'>
                        16,000 <span className="fs-15">PCs</span>
                    </div>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        navigate("/wallet/shards/confirmation")
                    }} className="form-wrapper">
                        <TextBox
                            className="auth-form-input-absolute wallet-input-fs" id="placeholder-center"
                            label={translations.formatString(content.labelAddress)}
                            placeholder={translations.formatString(content.long_press_to_paste)}
                            onChange={e => setTo_address(e.target.value)}
                            value={to_address} required
                            containerStyle={{ marginTop: 35 }}
                            font_notosan={language === 'jp' ? 'f-notosan' : ''}
                            inputStyle={{ height: 55 }}
                            disabled={false}
                        />
                        <TextBox
                            className="auth-form-input-absolute wallet-input-fs" id="placeholder-center"
                            label={content.enter_amount}
                            // placeholder={translations.formatString(content.enter_amount)}
                            containerStyle={{ marginTop: 35 }}
                            inputStyle={{ marginBottom: 5, height: 55 }}
                            style={{ width: 247 }}
                            onChange={e => setAmount(e.target.value)} value={amount} required
                            font_notosan={language === 'jp' ? 'f-notosan' : ''}
                        />
                        <div className='flex-row flex-center mt-5'>
                            <Btn type="button"
                                intent="outline"
                                className={`w-100 me-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                                onClick={showModalHandler}
                            >
                                {translations.formatString(buttonlist.cancelBtn)}
                            </Btn>
                            <Btn type="submit"
                                disabled={false}
                                className={`w-100 ms-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                            >
                                {translations.formatString(buttonlist.nextBtn)}
                            </Btn>
                        </div>
                    </form>

                </div>
            </AppWrapper>  </>
    )
}

export default ShardSendAddress