import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AppWrapper from '../../components/wrappers/AppWrapper'
import { BorderBoxText, Detail } from './Wallet';
import { LevicaTransactionStatus, OrderStatus, orderCurrency, TokenType } from '../../components/common/enum';
import { WalletPageTitle } from '../../components/common/Title';
import { formatMoney, isJP, isObject, swalText } from '../../components/common/commonFnc';
import Swal, { SwalBoxContent, SwalCorrect, SwalCustom, SwalWrong, SwalSettings, SwalOffer } from '../../components/common/Swal';
import { useDidUpdateEffect } from '../../services/hook';
import { Web3authContext } from '../../context/web3authContext';
import { translations } from "../../services/localization";
import { Btn, LaddaButton } from '../../components/common/Button';
import { TextBox } from '../../components/common/Form';
import { ReactComponent as OctillionLogo } from '../../assets/images/octillion-logo.svg';
import { ReactComponent as LevicaLogo } from '../../assets/images/levica-logo.svg';
import { ReactComponent as LevicaIcon } from '../../assets/images/levica-icon.svg';
import { postData } from '../../services/fetch';
import RPC from '../../components/common/web3RPC'
import { TowardGradientIcon } from '../../components/common/Icon';
import { REACT_APP_MARKETPLACE_ENDPOINT, REACT_APP_MP_WALLET_ADDRESS } from '../../config';
import { OnLoading } from '../../components/common/OnLoading';
import { getBidInfo, getWalletAddress, removeBidInfo, setWalletAddress } from "../../services/storage";
import { BottomModal } from "../../components/common/BottomModal";
import { useMediaQuery } from 'react-responsive'
import { useForceUpdate } from "../../services/hook";
import { appContext } from "../../context/appContext";
import { useDispatch } from 'react-redux';
import { clearItems, toggleSelectionMode } from '../../store/MyArcana';
import { TokenCard } from './OfferPrice';

const OfferConfirmation = () => {
    const { wallet_address, web3auth, provider, setProvider, init, approveContract, transferContract, isValidWalletAddress, logout, loginIfNotLogin, setWallet_address } = useContext(Web3authContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [token, setToken] = useState({})
    const [order, setOrder] = useState({})
    const [tokens, setTokens] = useState({})

    const [item, setItem] = useState({});
    const [startApprove, setStartApprove] = useState(false);
    const [startTransfer, setStartTransfer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deepLink, setDeepLink] = useState(''); //https://levica.page.link/MoogfNsxWpkd3knV8?token=44274da0d2a7885976689db788a3b3cd28f157ae2217b3689d7af1390634d2cfbb68495656a33deef944f3403a2eebbe66f95cae17cdc2d9de25a0b7bbbc15d401d043619973e3cb179aada7a127f03c10a829dfff187158463661aba33cacaed769be1c45656d8956a7479b8005d39bd92ee306fe4ef4a515ac53b39251fc285ff632fb7bfa4ae19e59d0dc87f25df0
    const { language } = useContext(appContext)
    const forceUpdate = useForceUpdate();
    const [displayModal, setDisplayModal] = useState(false);
    const content = translations.offer;
    const buttonlist = translations.buttonlist;
    const [id_token, setIdToken] = useState(null);
    const [showPaymentRedirectModal, setShowPaymentRedirectModal] = useState(false);
    const isPh = useMediaQuery({ maxWidth: 576 })
    const height = isPh ? window.innerHeight - 260 : '';
    // const [mobile, setMobile] = useState()
    // useEffect(() => {
    //     setMobile(isMobile)
    // }, [isMobile])

    useEffect(() => {
        const bidInfo = JSON.parse(getBidInfo());
        console.log({ bidInfo })
        if (bidInfo && bidInfo != {}) {
            setToken(bidInfo.token)
            setOrder(bidInfo.order)
            setTokens(bidInfo.tokens)
            setItem(bidInfo.token)
        }
    }, [])

    useEffect(() => {
        setWallet_address(getWalletAddress())
        if (!provider) {
            postData(`user/get-web3auth-token`).then(response => {
                const _id_token = response.data.web3auth_token
                setIdToken(_id_token)
            })
        } else {
            setProvider(provider)
            console.log("Provider set")
            const rpc = new RPC(provider);
            rpc.getAccounts().then(address => {
                console.log(address)
                setWalletAddress(address)
                setWallet_address(address)
            });
        }
    }, [provider]);

    useEffect(() => {
        if (id_token) {
            loginIfNotLogin(id_token)
        }
    }, [id_token])

    const handleSubmit = async () => {
        setLoading(true);
        setStartApprove(true);
    }

    const handleTransfer = async () => {
        setLoading(true);
        setStartTransfer(true);
    }

    const handleCancel = () => {
        setLoading(false);
        // stopCheckingLevicaStatus();
        navigate(`/wallet/${TokenType.getRouteName(token.token_type_id)}/detail/${token.token_id}`);
    }

    const goToPay = async () => {
        if (order.currency === orderCurrency.ANIMA) {
            handleTransfer();
        } else {

            SwalOffer(isJP(), 'p-t-60 p-t-sm-0').fire({
                allowEscapeKey: false,
                // title: !showPaymentRedirectModal && (<LevicaLogo style={{ width: 122, marginTop: 8 }} />),
                html: (<LevicaPayContent togglePaymentRedirectModal={(val) => setShowPaymentRedirectModal(val)} navigate={navigate} paymentInfo={content.biddingPaymentInfo} order={order} wallet_address={wallet_address} item={item} notifyParagraph={translations.formatString(content.notifyBidParagraph)} />)
            });
        }
    }

    useEffect(() => {
        return () => {
            setStartApprove(false);
            // setStartTransfer(false);
            removeBidInfo();
        };
    }, [])

    useEffect(() => {
        forceUpdate();
    }, [language])

    const approveToken = async () => {
        const isValid = await isValidWalletAddress();
        console.log({ isValid })
        if (isValid) {
            console.log('approve', token ? { token } : { tokens })
            if (token && token.token_type_id && token.token_id) {
                const tx_hash = await approveContract(token.token_type_id, token.token_id)
                    .catch(async e => {
                        setLoading(false);
                        await SwalWrong.fire({
                            title: 'エラーが発生しました',
                            text: (typeof e === 'string') ? e : e.message
                        });
                    });
                if (tx_hash) {
                    const response = await sellToken(tx_hash);
                    checkResponse({ response, transaction_id: tx_hash });

                }
            } else if (tokens) {
                const responses = [];
                for (let i = 0; i < tokens.length; i++) {
                    const it = tokens[i];
                    if (it.token_type_id && it.token_id) {
                        const tx_hash = await approveContract(it.token_type_id, it.token_id)
                            .catch(async e => {
                                setLoading(false);
                                await SwalWrong.fire({
                                    title: 'エラーが発生しました',
                                    text: `${(typeof e === 'string') ? e : e.message} for token ${it.token_id} of type ${it.token_type_id}`
                                });
                            });
                        if (tx_hash) {
                            const response = await sellToken(tx_hash, i);
                            responses.push({ response, transaction_id: tx_hash });
                        }
                    }
                }
                for (let it of responses) {
                    const isLast = responses[responses.length - 1]["token_id"] === it.token_id;
                    await checkResponse(it, isLast);
                    if (isLast) {
                        dispatch(clearItems());
                        dispatch(toggleSelectionMode());
                    }
                }

            } else {

                setLoading(false);
                SwalWrong.fire({
                    title: 'エラーが発生しました',
                    text: 'Please choose the token to sell.'
                });
                navigate(`/wallet/${TokenType.getRouteName((token || tokens[0]).token_type_id)}/detail/${(token || tokens[0]).token_id}`);
            }
        } else {

            setLoading(false);
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'Different wallet is connected. If you change wallet, go to wallet connect page and disconnect wallet first.'
            });
            logout();
        }
    }

    const sellToken = async (transaction_id, index) => {
        const orderObject = Object.assign(order, {
            currency: order.currency,
            transaction_id,
            wallet_address,
            token_type_id: (item || tokens[index])["token_type_id"],
            token_id: (item || tokens[index])["token_id"],
            category: TokenType.getContractAddress((item || tokens[index])["token_type_id"]),
        })
        return await postData(
            "orders/store",
            orderObject
        );
    }

    const checkResponse = async ({ response, transaction_id, redirect = true }) => {
        if (response.ok) {
            await SwalCorrect.fire({
                html: (<OfferResultContent navigate={navigate} type={OrderStatus.Ask} notifyParagraph={translations.formatString(content.notifyAskParagraph)} tx_hash={transaction_id} />)
            }).then((result) => {
                redirect && navigate('/wallet');
            });
        }
    }

    const buyToken = async (transaction_id) => {
        var response = await postData(
            "orders/store",
            Object.assign(order, {
                currency: order.currency,
                anima_txhash: transaction_id,
                wallet_address,
                category: TokenType.getContractAddress((item || tokens[0]).token_type_id),
            })
        );
        if (response.ok) {
            const anima_txhash = response.data.order.anima_txhash;
            const res = await postData(`orders/levica/status`, {
                levica_transaction_id: null,
                currency: order.currency,
                anima_txhash,
            });
            if (res.ok) {
                setLoading(false);
                if (
                    res.data.status === LevicaTransactionStatus.payment_completed
                ) {
                    SwalCorrect.fire({
                        html: (<OfferResultContent type={OrderStatus.Bid} notifyParagraph={translations.formatString(content.notifyBidParagraph)} tx_hash={transaction_id} />)
                    }).then((result) => {
                        navigate('/wallet');
                    });
                }
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    const transferToken = async () => {
        const isValid = await isValidWalletAddress();
        const to_address = REACT_APP_MP_WALLET_ADDRESS;
        if (isValid) {
            if (token && to_address) {
                transferContract(
                    order.currency === orderCurrency.ANIMA ? TokenType.Anima : (token || tokens[0]).token_type_id,
                    to_address,
                    order.amount,
                    null,
                    true,
                    (tx_hash) => {
                        setStartTransfer(false);
                        buyToken(tx_hash);
                    }
                );

            } else {

                setLoading(false);
                SwalWrong.fire({
                    title: 'エラーが発生しました',
                    text: 'Please choose the token to sell.'
                });
                navigate(`/wallet/arcana/detail/${(token || tokens[0]).token_type_id}/${(token || tokens[0]).token_id}`);
            }
        } else {

            setLoading(false);
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'Different wallet is connected. If you change wallet, go to wallet connect page and disconnect wallet first.'
            });
            logout();
        }
    }

    let title_header = 'send';
    let main_title = 'send';
    if (order.type == OrderStatus.Ask) {
        title_header = translations.formatString(content.AskOffer);
        main_title = translations.formatString(content.confirmAskOffer);
    }
    else if (order.type == OrderStatus.Bid) {
        title_header = translations.formatString(content.BidOffer);
        main_title = translations.formatString(content.confirmBidOffer);
    }

    useDidUpdateEffect(() => {
        if (startApprove && provider) {
            approveToken();
        }
    }, [provider, startApprove])

    useDidUpdateEffect(() => {
        console.log("startTransfer", startTransfer, provider)
        if (startTransfer && provider) {
            transferToken();
        }
    }, [provider, startTransfer])

    const isPC = useMediaQuery({ minWidth: 992 })
    const showModalHandler = () => {
        isPC ? onCancelEvent() : setDisplayModal(true)
    }
    const onCancelEvent = () => {
        SwalSettings.fire({
            text: order.type === OrderStatus.Ask ? content.cancelAskConfirm : content.cancelBidConfirm,
            confirmButtonText: swalText(translations.formatString(buttonlist.yesBtn)),
            cancelButtonText: swalText(translations.formatString(buttonlist.noBtn)),
        }).then((result) => {
            if (result.isConfirmed) {
                navigate(`/wallet/${TokenType.getRouteName(token.token_type_id)}/detail/${token.token_id}`)
            }
        });
    }

    return (
        <AppWrapper title={title_header} disableFooter disabled={loading} hideBottomNav={displayModal}>
            {
                (item || tokens) ?
                    <div className=''>
                        <div className='offer-confirmation-container mx-auto d-flex flex-column justify-content-center'>
                            <div class="w-100 details-container" style={{ height: height }}>
                                <WalletPageTitle title={main_title} hasUnderline />
                                <Detail name={translations.formatString(content.itemCategory)} value={'ANICANA chain'} />
                                {/* {token ? <Detail name={translations.formatString(content.symbolAndTokenName)} value={`${TokenType.getEnumName(item.token_type_id)} - ${item.name}`} />
                                : <Detail name={translations.formatString(content.symbolAndTokenName)}
                                    value={<div className='d-flex flex-column' style={{ gap: 5 }}>
                                        {tokens.map((it, index) => {
                                            return (<div>
                                                {`${TokenType.getEnumName(it.token_type_id)} - ${it.name}`}
                                            </div>)
                                        })}
                                    </div>} />} */}
                                <Detail name={translations.formatString(content.symbolAndTokenName)} />
                                {token ? (
                                    <div className='d-flex flex-column mb-3 mx-3'>
                                        <TokenCard containerStyle="w-100" elementStyle="w-100" image={token.ipfs_image || token.image_url || token.image || null} name={token.name} token_id={token.token_id} token_type_id={token.token_type_id} />
                                    </div>
                                ) : tokens.map((item, index) => (
                                    <div className='d-flex flex-column mb-3 mx-3'>
                                        <TokenCard containerStyle="w-100" elementStyle="w-100" key={index} image={item.ipfs_image || item.image_url || item.image || null} name={item.name} token_id={item.token_id} token_type_id={item.token_type_id} />
                                    </div>
                                ))}
                                <Detail name={translations.formatString(content.tokenId)} value={token ? order.token_id : tokens.map(it => it.token_id).join(",")} />
                                <Detail name={translations.formatString(content.txCategory)} value={OrderStatus.getEnumName(order.type)} />
                                <Detail name={translations.formatString(content.price)} value={formatMoney(order.amount, order.currency === orderCurrency.ANIMA ? 'ANM' : '¥')} />
                                {
                                    order.type === OrderStatus.Ask &&
                                    <Detail name={translations.formatString(content.providerCharge)} value={orderCurrency.ANIMA ? '5%' : '10%'} />
                                }
                                <div className={`${order.type === OrderStatus.Ask ? 'flex-column' : 'flex-row'} flex-center mt-5`}>
                                    
                                    {/*<button type="button" className='btn btn-custom-active ms-2 w-100' onClick={handleSubmit}>{translations.formatString(buttonlist.submitBtn)}</button>*/}
                                </div>
                                {
                                    <BottomModal
                                        onCloseHandler={() => setDisplayModal(!displayModal)}
                                        onShow={displayModal}
                                        label={order.type === OrderStatus.Ask ? content.cancelAskConfirm : content.cancelBidConfirm}
                                        cancelBtnLabel={translations.formatString(buttonlist.yesBtn)}
                                        BackBtnLabel={translations.formatString(buttonlist.noBtn)}
                                        navigation={`/wallet/${TokenType.getRouteName((token || tokens[0])["token_type_id"])}/detail/${(token || tokens[0])["token_id"]}`}
                                    />
                                }
                            </div>
                            <div className='d-flex flex-row justify-content-between align-items-center px-lg-3 offer-box-container'>
                                {
                                    order.type === OrderStatus.Ask ?
                                        <>
                                            <Btn intent="outline" type="button" className='me-2' style={{ width: 160 }} onClick={showModalHandler}>{translations.formatString(buttonlist.cancelBtn)}</Btn>
                                            <Btn type="button" loading={loading} className='ms-2' style={{ width: 160 }} onClick={handleSubmit}>{translations.formatString(buttonlist.confirmBtn)}</Btn>
                                        </>
                                        :
                                        <>
                                            <Btn intent="outline" type="button" className='me-2' style={{ width: 160 }} onClick={showModalHandler}>
                                                {translations.formatString(buttonlist.cancelBtn)}
                                            </Btn>
                                            <Btn type="button" loading={loading} className='ms-2' style={{ width: 160 }} onClick={goToPay}>{translations.formatString(buttonlist.goToPayBtn)}</Btn>
                                        </>
                                }
                            </div>
                        </div>
                    </div> : <OnLoading />
            }
        </AppWrapper>
    )
}

export default OfferConfirmation

export const OfferBoxContent = ({ title, onCancel, onSubmit, type, defaultValue = '', currentBidOffer }) => {
    const buttonlist = translations.buttonlist;
    const commonlist = translations.common;
    const offerTxt = translations.offer;
    const [offer, setOffer] = useState(defaultValue);
    const changePrice = (val) => {
        if (val != '' && val < 1) {
            val = 1
        }

        setOffer(val)

    };
    return (
        <SwalBoxContent
            title={title}
            disableConfirmBtn={!offer}
            confirmBtnVariant={'active'}
            confirmBtnText={translations.formatString(type === OrderStatus.Ask ? buttonlist.enterBtn : buttonlist.confirmBtn)}
            onCancel={onCancel}
            onSubmit={() => onSubmit(offer)}
            noTitleMargin={true}
        >
            <div className='row'>
                {
                    type == OrderStatus.Ask &&
                    <div className={'col-12 mb-5'}>
                        <span>
                            {translations.formatString(offerTxt.currentBidOffer)}:
                        </span>
                        <span className={'ml-3 subHeader'} style={{ marginLeft: '20px' }}>
                            {translations.formatString(commonlist.formattedYen, currentBidOffer || 0)}
                        </span>
                    </div>
                }
                <div className='col px-0'>
                    <TextBox min={1} number={1} type={"numeric"} className="focus" inputStyle={{ height: 40 }} onChange={e => changePrice(e.target.value)} value={offer} />
                </div>
                <div className='d-flex ps-1 pe-0 pb-1' style={{ width: 40, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <span className='attribute-value' style={{ maxWidth: '100%' }}>{translations.formatString(commonlist.yen)}</span>
                </div>
            </div>
        </SwalBoxContent>
        // <div className='swal-react-modal flex-center text-white'>
        //     <div className='row mx-0'>
        //         <div className='col-12'>
        //             <WalletPageTitle title={title} />
        //         </div>
        //         <div className='col-12 px-0'>
        //             <div className='row' style={{ margin: '80px 0px 36px' }}>
        //                 <div className='col px-0'>
        //                     <TextBox number={1} className="focus" inputStyle={{ height: 40 }} onChange={e => setOffer(e.target.value)} value={offer} />
        //                 </div>
        //                 <div className='d-flex ps-1 pe-0 pb-1' style={{ width: 40, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        //                     <span className='attribute-value' style={{ maxWidth: '100%' }}>{translations.formatString(commonlist.yen)}</span>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='col-12 px-0'>
        //             <div className='flex-row flex-center mt-5'>
        //                 <button type="button" className='btn btn-custom-cancel small w-100 me-2' onClick={onCancel}>{translations.formatString(buttonlist.cancelBtn)}</button>
        //                 <button type="button" className={`btn btn-custom-${offer ? 'active' : 'success'} small ms-2 w-100`} disabled={!offer} onClick={() => onSubmit(offer)}>{translations.formatString(type === OrderStatus.Ask ? buttonlist.enterBtn : buttonlist.confirmBtn)}</button>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export const OfferResultContent = ({ notifyParagraph, tx_hash, type, navigate }) => {
    // const navigate = useNavigate();
    const onConfirmCopy = () => {
        SwalCorrect.fire({
            html: (<OfferResultContent navigate={navigate} type={type} notifyParagraph={notifyParagraph} tx_hash={tx_hash} />)
        }).then((result) => {
            navigate('/wallet');
        });
    }
    return (
        <div className='d-flex flex-column align-items-center pre-wrap w-100 fs-16'>
            <div style={{}}>{notifyParagraph}</div>
            <OctillionLogo style={{ marginTop: 50, marginBottom: 50, cursor: 'pointer' }} />
            {
                type === OrderStatus.Ask &&
                <>
                    <span style={{ marginBottom: 16, fontSize: 20 }}>{type === OrderStatus.Ask ? 'ASK ID' : 'BID ID'}</span>
                    <BorderBoxText onConfirmCopy={onConfirmCopy} className={'w-100'} myWalletAddressClassName={'w-100'} disabledTextOverflow value={tx_hash} titleComponent={<></>} />
                </>
            }
        </div>
    )
}

export const ThirdPartyProviderBoxContent = ({ wallet_address, fnc }) => {
    const isJP = translations._language === 'jp';
    const [loading, setLoading] = useState(false);
    const checkOctillionUser = async () => {
        setLoading(true)
        if (wallet_address) {
            const response = await postData(`user/check`, { wallet_address });
            setLoading(false)
            if (response.ok) {
                const isOctillionUser = response.data.user_exists;
                if (isOctillionUser) {
                    Swal.close();
                    fnc();
                } else {
                    SwalCustom.fire({
                        showConfirmButton: false,
                        showCloseButton: true,
                        customClass: {
                            closeButton: isJP ? 'swal2-custom-close-jp mr-minus-10' : 'swal2-custom-close mr-minus-10'
                        },
                        title: (<OctillionLogo style={{ width: 100, marginTop: 8 }} />),
                        html: (<OctillionCreateContent />)
                    });
                }
            }
        }
    }

    return (
        <>
            <div className='d-flex flex-column align-items-center'>
                {loading === true ?
                    <div style={{ height: 150 }}>
                        <OnLoading pRelative marginAuto />
                    </div>
                    :
                    <>
                        <WalletPageTitle className='text-white' title={translations.offer.select} />
                        <WalletPageTitle className='text-white' underlineClassName='w-100' title={translations.offer.thirdPartyProvider} hasUnderline />
                        <div onClick={checkOctillionUser} style={{ marginTop: 35, marginBottom: 80 }} className='d-flex align-items-center pointer octillionLogo'><OctillionLogo style={{ marginRight: 16 }} /><TowardGradientIcon /></div>
                    </>
                }
            </div>
        </>
    )
}

export const OctillionCreateContent = ({ }) => {
    const content = translations.offer;
    const buttonlist = translations.buttonlist;
    return (
        <div className='d-flex flex-column align-items-center text-white'>
            <span className='f-monodb fw-400' style={{ fontSize: 35, marginTop: 46, marginBottom: 32 }}>Create Account</span>
            <span className='f-regular fw-400 text-justify pre-wrap' style={{ fontSize: 16, marginBottom: 74 }}>
                {content.createOctillionParagraph}
            </span>
            <span className='f-regular fw-500 text-center' style={{ fontSize: 13 }}>
                {content.nonOctillionText}
            </span>
            <button type="button" className='btn btn-custom-cancel w-100 f-notosan fs-20' style={{ marginBottom: 36 }} onClick={() => window.location.href = REACT_APP_MARKETPLACE_ENDPOINT + "register?r=" + window.location.href}>{translations.formatString(buttonlist.createOctillionBtn)}</button>
        </div>
    )
}

export const LevicaPayContent = ({ paymentInfo, order, wallet_address, item, notifyParagraph, navigate, togglePaymentRedirectModal }) => {
    const buttonlist = translations.buttonlist;
    const [levica_transaction_id, setLevica_transaction_id] = useState(''); //Da3e85596ddc0b29652d6c35931c9269b758580771583458210c27eb53c4a5ea4
    const [levica_approve_transaction_id, setLevica_approve_transaction_id] = useState(''); //Da3e85596ddc0b29652d6c35931c9269b758580771583458210c27eb53c4a5ea4
    const [levica_transaction_status, setLevica_transaction_status] = useState('');
    const [levica_status_check_interval, setLevica_status_check_interval] = useState('');
    const [levica_status_check_time, setLevica_status_check_time] = useState(0); // in second
    const [deeplinkLoading, setDeeplinkLoading] = useState(false)
    const [weblinkLoading, setWeblinkLoading] = useState(false)
    const [redirect_uri, setRedirect_uri] = useState(undefined)
    const interval = 5; // 5sec
    const maxTimeInterval = 300; // 5min = 300sec
    const levica_transaction_id_ref = useRef();
    levica_transaction_id_ref.current = levica_transaction_id;
    const levica_status_check_time_ref = useRef();
    levica_status_check_time_ref.current = levica_status_check_time;
    const levica_status_check_interval_ref = useRef();
    levica_status_check_interval_ref.current = levica_status_check_interval;

    useEffect(() => {
        if (redirect_uri) {
            console.log(redirect_uri, { red: !!redirect_uri }, !!(redirect_uri && redirect_uri.url))
            togglePaymentRedirectModal(!!(redirect_uri && redirect_uri.url));
        }
    }, [redirect_uri])

    const buyToken = async (payment_type) => {
        if (payment_type === 'qr') {
            setDeeplinkLoading(true);
        } else if (payment_type === 'web') {
            setWeblinkLoading(true);
        }
        var response = await postData(
            "orders/store",
            Object.assign(order, {
                currency:
                    order.currency === orderCurrency.ANIMA
                        ? orderCurrency.ANIMA
                        : orderCurrency.YEN,
                wallet_address,
                category: TokenType.getContractAddress(item.token_type_id),
                payment_type,
            })
        );
        if (response.ok) {
            // setDeepLink('https://levica.page.link/MoogfNsxWpkd3knV8?token=44274da0d2a7885976689db788a3b3cd28f157ae2217b3689d7af1390634d2cfbb68495656a33deef944f3403a2eebbe66f95cae17cdc2d9de25a0b7bbbc15d401d043619973e3cb179aada7a127f03c10a829dfff187158463661aba33cacaed769be1c45656d8956a7479b8005d39bd92ee306fe4ef4a515ac53b39251fc285ff632fb7bfa4ae19e59d0dc87f25df0');
            setLevica_transaction_id(response.data.order.levica_draft_transaction_id);
            if (payment_type === 'qr') {
                setRedirect_uri({ url: response.data.order.levica_deep_link, payment_type });
            } else if (payment_type === 'web') {
                setRedirect_uri({ url: response.data.order.web_url, payment_type });
            }
            if (payment_type === 'qr' || payment_type === 'web') {
                const levica_status_check_interval = setInterval(checkLevicaStatus, interval * 1000)
                setLevica_status_check_interval(levica_status_check_interval);
            }
        } else {
            setDeeplinkLoading(false);
            setWeblinkLoading(false);
            onClosePaymentRedirectModal();
        }
    }

    const checkLevicaStatus = async () => {
        console.log('tx_id', levica_transaction_id_ref.current)
        if (levica_status_check_time_ref.current <= maxTimeInterval || levica_status_check_time_ref.current === 0) {
            setLevica_status_check_time(total_interval => total_interval + interval)
            var response = await postData(`orders/levica/status`, {
                levica_transaction_id: levica_transaction_id_ref.current,
                currency: order.currency,
                anima_txhash: null,
            });
            if (response.ok) {
                setLevica_transaction_status(response.data.status);
                if (response.data.approve_transaction_id) {
                    setLevica_approve_transaction_id(response.data.approve_transaction_id);
                }
            }
        } else {
            stopCheckingLevicaStatus(levica_status_check_interval_ref.current);
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'トランザクションの保留が長すぎます。 取引はキャンセルされます。'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/wallet');
                }
            });
        }
    }

    const stopCheckingLevicaStatus = (levica_status_check_interval, loadingStop = true) => {
        if (levica_status_check_interval) {
            clearInterval(levica_status_check_interval);
        }
        if (loadingStop) {
            setDeeplinkLoading(false);
            setWeblinkLoading(false);
            onClosePaymentRedirectModal();
        }
    }

    const onClosePaymentRedirectModal = () => {
        setRedirect_uri(undefined);
        togglePaymentRedirectModal(false);
    }

    useEffect(() => {
        if (levica_transaction_status === LevicaTransactionStatus.payment_completed || levica_transaction_status === LevicaTransactionStatus.transaction_completed) {
            stopCheckingLevicaStatus(levica_status_check_interval);
            SwalCorrect.fire({
                html: (<OfferResultContent navigate={navigate} type={OrderStatus.Bid} notifyParagraph={notifyParagraph} tx_hash={levica_approve_transaction_id} />)
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/wallet');
                }
            });
        } else if (levica_transaction_status === LevicaTransactionStatus.transaction_fail || levica_transaction_status === LevicaTransactionStatus.transaction_canceled) {
            stopCheckingLevicaStatus(levica_status_check_interval);
            SwalWrong.fire({
                title: 'エラーが発生しました',
                text: 'トランザクションが失敗またはキャンセルされました。'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/wallet');
                }
            });
        }

        return () => {
            stopCheckingLevicaStatus(levica_status_check_interval, false);
        };
    }, [levica_transaction_status])

    const openRedirect_uri = (url, payment_type) => {
        if (url && payment_type) {
            if (payment_type === 'qr') {
                var wnd = window.open(url);
                setTimeout(function () {
                    wnd.close();
                }, 5000);
                onClosePaymentRedirectModal();
            } else if (payment_type === 'web') {
                window.open(url);
                onClosePaymentRedirectModal();
            }
        }
    }

    if (redirect_uri && isObject(redirect_uri))
        return (
            <PaymentRedirectModal onClick={() => openRedirect_uri(redirect_uri.url, redirect_uri.payment_type)} />
            // <PaymentRedirectModal onClick={() => openRedirect_uri("https://google.com", "qr")} />
        )

    return (
        <div>
            <LevicaLogo style={{ width: 122, marginTop: 8, marginBottom: 4 }} />
            <div className='d-flex flex-column align-items-center'>
                <div className='d-flex flex-column align-items-center' style={{ padding: 24, paddingTop: 41 }}>
                    <span className='text-justify' style={{ fontSize: 16 }}>{paymentInfo}</span>
                    <LevicaIcon style={{ marginTop: 36, marginBottom: 12 }} />
                </div>
                {/* <LaddaButton type="button" loading={deeplinkLoading} style={{ marginBottom: 32 }} className='btn btn-custom-active w-100 fs-25' onClick={() => buyToken('qr')}>{translations.formatString(buttonlist.proceedWithApp)}</LaddaButton> */}
                <LaddaButton type="button" loading={weblinkLoading} style={{ marginBottom: 32 }} className={`btn btn-custom-active w-100 ${weblinkLoading ? 'fs-20' : 'fs-20'}`} onClick={() => buyToken('web')}>
                    {translations.formatString(buttonlist.proceedWithWebsite)}
                </LaddaButton>
            </div>
        </div>
    )
}

const PaymentRedirectModal = ({ onClick }) => {
    const content = translations.offer;
    const handleClick = () => {
        onClick();
    }
    return (
        <div className='flex-center mb-3 pointer' onClick={handleClick}>
            <span className='fs-25 text-center pre-wrap'>{translations.formatString(content.tapToOpenPaymentPage)}</span>
        </div>
    )
}