import { useState } from "react"
import { TextBox } from "../../components/common/Form"
import { WalletPageTitle } from "../../components/common/Title"
import AppWrapper from "../../components/wrappers/AppWrapper"
import { strings, translations } from "../../services/localization"
import { Btn } from "../../components/common/Button"
import { useNavigate } from "react-router-dom"
import { SwalSettings } from "../../components/common/Swal"
import { useMediaQuery } from "react-responsive"
import { formatNumber, isJP, swalText } from "../../components/common/commonFnc"
import { BottomModal } from "../../components/common/BottomModal"
import { Detail } from "../wallet/Wallet"
import { Warning2Icon, WarningIcon } from "../../components/common/Icon"

const MultipleShardSendAddress = () => {
    const content = translations.walletShard
    const [to_address, setTo_address] = useState('')
    const [amount, setAmount] = useState('')
    const [showBottomModal, setShowBottomModal] = useState(false)
    const navigate = useNavigate()
    const language = translations._language;
    const buttonlist = translations.buttonlist;

    const isPC = useMediaQuery({ minWidth: 992 })

    const showModalHandler = () => {
        if (to_address || amount) {
            isPC ? onCancelEvent() : setShowBottomModal(true)
        } else {
            navigate('/wallet');
        }
    }
    const onCancelEvent = () => {
        SwalSettings.fire({
            text: strings.formatString(content.Are_you_sure_you_want_to_cancel_sending),
            confirmButtonText: swalText(buttonlist.yesBtn),
            cancelButtonText: swalText(buttonlist.noBtn),
        }).then((result) => {
            if (result.isConfirmed) {
                // navigate(-2);
            }
        });
    }

    const onCloseBottomModal = () => {
        setShowBottomModal(false)
    }

    return (
        <>
            {
                <BottomModal
                    onCloseHandler={onCloseBottomModal}
                    onShow={showBottomModal}
                    label={translations.formatString(content.Are_you_sure_you_want_to_cancel_sending)}
                    cancelBtnLabel={translations.formatString(buttonlist.yesBtn)}
                    BackBtnLabel={translations.formatString(buttonlist.noBtn)}
                    navigation='/wallet/shards/'
                />
            }
            <AppWrapper title={translations.formatString(content.headerTitle)}>
                <WalletPageTitle className='text-white p-x-16' title={translations.formatString(content.sendShards)} hasBackIcon />
                <div className="d-flex flex-column align-items-center mt-4">
                    <div className='d-flex flex-column mb-3 w-100' style={{ maxWidth: 450 }}>
                        <span className={`fw-400 text-white text-start ${isJP() ? 'f-notosan' : 'f-regular'}`}>Shard IDs / 6</span>
                        <div className="w-100 text-box-container mt-3" style={{ maxWidth: 450 }}>
                            <div className='text-box scrollable ' style={{ maxHeight: 205 }}>
                                {
                                    [...Array(6)].map((item, index) => (
                                        <>
                                            <div key={index}>1234567788901234567788901234567788901234567788901234567788901234567788901234</div>
                                            <div className="divider"></div>
                                        </>
                                    ))
                                } </div>
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-start justify-content-between" style={{ maxWidth: 450 }}>
                        <div className="flex-2">
                            <Detail nolabelpadding name={translations.formatString(content.labelAmountTotal)} value={formatNumber(4660)} isShard />
                        </div>
                        <div className="d-flex align-items-start flex-1 pt-1" style={{ maxWidth: 153 }}>
                            <div className="position-relative me-2" style={{ top: -4 }}>
                                <Warning2Icon style={{ width: 16, height: 16 }} />
                            </div>
                            <div className="fs-12 f-condensed fw-light">
                                Shard numbers cannot be set separately in case of multiple selection.
                            </div>
                        </div>
                    </div>

                    <div className="w-100" style={{ maxWidth: 450 }}>

                        <TextBox
                            className="auth-form-input-absolute wallet-input-fs" id="placeholder-center"
                            label={translations.formatString(content.labelAddress)}
                            placeholder={translations.formatString(content.long_press_to_paste)}
                            onChange={e => setTo_address(e.target.value)}
                            value={to_address} required
                            containerStyle={{ marginTop: 35 }}
                            font_notosan={language === 'jp' ? 'f-notosan' : ''}
                            inputStyle={{ height: 55 }}
                            disabled={false}
                        />
                    </div>
                    <div className='flex-row flex-center mt-5 form-wrapper'>
                        <Btn type="button"
                            intent="outline"
                            className={`w-100 me-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                            onClick={showModalHandler}
                        >
                            {translations.formatString(buttonlist.cancelBtn)}
                        </Btn>
                        <Btn type="submit"
                            disabled={false}
                            className={`w-100 ms-2 ${language === 'jp' ? 'f-notosan' : 'f-regular'}`}
                        >
                            {translations.formatString(buttonlist.submitBtn)}
                        </Btn>
                    </div>


                </div>
            </AppWrapper>  </>
    )
}

export default MultipleShardSendAddress