import moment from "moment"
import { useContext, useEffect, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useNavigate, useParams } from "react-router-dom"
import { ReactComponent as LineBreak } from '../../assets/icons/line-break.svg'
import { BackIcon, ChevronRightIcon, ReceiveIcon, SendIcon } from "../../components/common/Icon"
import { OnLoading } from "../../components/common/OnLoading"
import { WalletPageTitle } from "../../components/common/Title"
import { calculateBN, formatNumber } from "../../components/common/commonFnc"
import AppWrapper from "../../components/wrappers/AppWrapper"
import { appContext } from "../../context/appContext"
import { Web3authContext } from "../../context/web3authContext"
import { getData, postData } from "../../services/fetch"
import { strings, translations } from "../../services/localization"
import WalletAddressRow from "../wallet/WalletAddressRow"
import { getWalletAddress } from "../../services/storage"
import { TokenCategoryNavRow } from "../../components/common/FlexNavRow"
import { TokenType } from "../../components/common/enum"
import { useIntersectionWithLastRef } from "../../hooks/useIntersectionWithLastRef"



const ShardTransaction = () => {
    const content = translations.walletShard
    const { id } = useParams();
    const isPC = useMediaQuery({ minWidth: 992 })
    const navigate = useNavigate()

    const { token_id } = useParams();
    const [item, setItem] = useState({});
    const { timeZone } = useContext(appContext);
    const [last_tx_id, setLast_tx_id] = useState(-1);
    const [shardHistory, setShardHistory] = useState([]);
    const [showBottomModal, setShowBottomModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const tokenDetailPage = translations.tokenDetailPage;
    const language = translations._language;
    const walletTableLabel = translations.walletTableLabel;
    const [balance, setBalance] = useState('-')
    const [showFullBalance, setShowFullBalance] = useState(false)
    const [id_token, setIdToken] = useState(null);
    const [page, setPage] = useState(1);
    const [last_page, setLast_page] = useState(1);
    const [total, setTotal] = useState(0);
    const [expand, setExpand] = useState(false);
    const [expandIndex, setExpandIndex] = useState()
    const buttonlist = translations.buttonlist;
    const [has_previous_page, set_has_previous_page] = useState(1);
    const [has_next_page, set_has_next_page] = useState(1);
    const [paginationLoading, setPaginationLoading] = useState(false)
    // const [loading, setLoading] = useState(true)
    const limit = 10;
    const [manaItems, setManaItems] = useState(0)
    const [manaInfoCount, setManaInfoCount] = useState(null)
    const [drawHistories, setDrawHistories] = useState([]);
    const [drawHistoryPage, setDrawHistoryPage] = useState(1);
    const [drawHistory_has_previous_page, setDrawHistory_has_previous_page] = useState(1);
    const [drawHistory_has_next_page, setDrawHistory_has_next_page] = useState(1);
    const [txHistories, setTxHistories] = useState([]);
    const [txHistoryFirstId, setTxHistoryFirstId] = useState(0);
    const [txHistoryLastId, setTxHistoryLastId] = useState(0);
    const [previousLastid, setPreviousLastid] = useState(0);
    const [txHistoryHasPreviousPage, setTxHistoryHasPreviousPage] = useState(false);
    const [txHistoryHasNextPage, setTxHistoryHasNextPage] = useState(false);
    const [txHistoryTotal, setTxHistoryTotal] = useState(0);
    const [txHistoryCurrentCount, setTxHistoryCurrentCount] = useState(0);
    const [txHistoryNewCount, setTxHistoryNewCount] = useState(0);
    const txHistoryPaginationCount = useRef(1);

    const { wallet_address, provider, init, loginIfNotLogin, setWallet_address } = useContext(Web3authContext);
    const limitPerPage = 10

    useEffect(() => {
        const address = getWalletAddress()
        if (!address) {
            navigate('/')
            return
        }
        setWallet_address(address)

        async function fetchData() {
            const promises = [
                getTxHistoryTransactions(address),
            ]
            await Promise.allSettled(promises);
            setLoading(false)
            setPaginationLoading(false)
        }

        if (language && timeZone) {
            fetchData();
        }
    }, [timeZone]);

    const previousTxHistory = () => {
        setPaginationLoading(true)
        --txHistoryPaginationCount.current
        getTxHistoryTransactions(wallet_address, txHistoryFirstId, 'ASC')
    }

    const nextTxHistory = () => {
        setPaginationLoading(true)
        ++txHistoryPaginationCount.current
        getTxHistoryTransactions(wallet_address, txHistoryLastId)
    }

    // const getBalance = async (contract_address) => {
    //     if (!contract_address) {
    //         return;
    //     }
    //     const rpc = new RPC(provider);
    //     const _balance = await rpc.getCustomTokenBalance(contract_address, getWalletAddress(), 0, true);
    //     setBalance(_balance)
    // };

    const getTxHistoryTransactions = async (wallet_address, last_id = -1, sort_order = 'DESC', is_previous = 0) => {
        // setLoading(true);
        setPreviousLastid(last_id)
        const response = await getData(`tokens/transactions?wallet_address=${wallet_address}&categories=["${id}"]&limit=${limit}&last_tx_id=${last_id}&sort_order=${sort_order}&timezone=${timeZone}`);
        setPaginationLoading(false)
        if (response.ok) {
            const { data, has_next_tx_history, has_previous_tx_history, total } = response.data
            const tx_data = data.map((i) => ({ ...i, amount: calculateBN(i.amount, i.decimal, 2) || 0 })).sort((a, b) => b.tx_id - a.tx_id)
            const sortedTx = [...tx_data].sort((a, b) => a.tx_id - b.tx_id);
            if (isPC) {
                setTxHistories(tx_data)
            } else {
                setTxHistories(prev => ([...prev, ...tx_data]))
            }
            setTxHistoryHasNextPage(has_next_tx_history)
            setTxHistoryHasPreviousPage(has_previous_tx_history)
            setTxHistoryFirstId(sortedTx[sortedTx.length - 1]?.tx_id || 0)
            setTxHistoryLastId(tx_data[tx_data.length - 1]?.tx_id || 0)
            setTxHistoryTotal(total)
            if (!is_previous) {
                setTxHistoryCurrentCount(has_next_tx_history ? txHistoryPaginationCount.current * limit : txHistoryCurrentCount + tx_data.length)
            }
            setTxHistoryNewCount([...tx_data].filter((i) => i.is_read === false)?.length)
        }
        setLoading(false);
    }

    useEffect(() => {
        setTxHistoryNewCount(0)
        getTxHistoryTransactions(wallet_address, previousLastid, 'DESC', 1)
    }, [])

    const { ref } = useIntersectionWithLastRef(paginationLoading, txHistoryHasNextPage,
        () => {
            setPaginationLoading(true)
            getTxHistoryTransactions(wallet_address, txHistoryLastId)
        })

    const onChangeTokenType = (type) => {
        if (type === 'send') {
            navigate(`/wallet/send/address`, { state: { token: { token_type_id: TokenType.Token }, token_type_id: TokenType.Token, user_token_id: token_id } })
        } else if (type === 'receive') {
            navigate('/wallet/receive')
        } else if (type === 'delete') {
            setShowBottomModal(true)
        }
    }

    const prevDrawPage = () => {
        if (has_previous_page) {
            setPage(page => page - 1);
        }
    }
    const nextDrawPage = () => {
        if (has_next_page) {
            setPage(page => page + 1);
        }
    }

    const currentTransactions = shardHistory?.filter((i, j) => j >= (page - 1) * limitPerPage && j < page * limitPerPage) || []

    const paginationCount = limitPerPage * page
    return (

        <>
            <AppWrapper title={translations.formatString(content.headerTitle)}>
                {
                    loading ? <OnLoading /> :
                        isPC ?
                            <div className='wallet-token'>
                                <WalletAddressRow />

                                <div className='d-flex align-items-center my-4'>
                                    <div onClick={() => navigate(-1)}>
                                        <BackIcon />
                                    </div>
                                    <span className='fs-35 text-uppercase m-l-20' style={{ fontFamily: 'Kardust Condensed' }}>
                                        {item.token_name}
                                    </span>
                                    <span className='fs-30 text-uppercase m-l-40 pointer' style={{ fontFamily: 'Kardust Condensed' }}
                                    // onClick={handleClickAnimaBalance}
                                    >
                                        {/* {Number(item.token_type_id) === Number(TokenType.Anima) ? showFullBalance ? (balance || 0) : cutNumber(balance || 0, 6) : (balance || 0)} TKN */}
                                        {formatNumber(16000)} PCs
                                    </span>
                                </div>

                                <div className='d-flex mb-5 align-items-center'>
                                    <TokenCategoryNavRow onChangeTokenType={onChangeTokenType} noswap/>
                                </div>

                                <div className='mb-3'>
                                    <span className='primary-color p-r-15'>
                                        {tokenDetailPage.transaction}
                                    </span>
                                    <span>{txHistoryTotal > 0 ? `${txHistoryCurrentCount} / ${txHistoryTotal}` : 0}</span>
                                </div>

                                <div className='table-responsive my-4'>
                                    <table className='w-100'>
                                        <tbody>
                                            <tr className='text-center transaction-table-header'>
                                                <th style={{ textAlign: 'left', minWidth: 80 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelAction)}
                                                </th>
                                                <th style={{ minWidth: 80 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelDate)}
                                                </th>
                                                <th style={{ minWidth: 217 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelTxID)}
                                                </th>
                                                <th style={{ minWidth: 161 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelFrom)}
                                                    {strings.formatString(walletTableLabel.labelSlash)}
                                                    {strings.formatString(walletTableLabel.labelTo)}
                                                </th>
                                                <th style={{ minWidth: 80 }} className="jp-fw-400">
                                                    {strings.formatString(walletTableLabel.labelAmount)}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    <div className='divider mb-3 mt-3'></div>
                                                </td>
                                            </tr >
                                            {!paginationLoading ?
                                                txHistories?.length > 0 && txHistories.map((item, index) =>
                                                    <tr
                                                        className='text-center transaction-table-body jp-f-notosan'
                                                        // onClick={() => {
                                                        //     Swal.fire({
                                                        //         html: (<WalletTransactionDetail wallet_address={wallet_address} transaction={item} />)
                                                        //     });
                                                        // }} 
                                                        key={index}
                                                    >
                                                        <td
                                                            className="table-row-data tx-ellipsis jp-f-notosan pe-2"
                                                            style={{ textAlign: 'left' }}
                                                        >
                                                            {wallet_address === item.from
                                                                ? <><SendIcon className="wallet-item-icon" /> {tokenDetailPage.Sent}</>
                                                                : <><ReceiveIcon className="wallet-item-icon" /> {tokenDetailPage.Received}</>
                                                            }
                                                        </td>
                                                        <td className="table-row-data jp-f-notosan pe-2">
                                                            {item.timestamp}
                                                        </td>
                                                        <td
                                                            className="table-row-data tx-ellipsis jp-f-notosan pe-2"
                                                            style={{ textAlign: 'left' }}
                                                        >
                                                            {item.tx_hash}
                                                        </td>
                                                        <td className="table-row-data jp-f-notosan pe-2" style={{ textAlign: 'left' }}>
                                                            {wallet_address === item.from
                                                                ? item.to
                                                                :
                                                                item.from
                                                            }
                                                        </td>
                                                        <td className="table-row-data p-0 jp-f-notosan">{item.amount || 1}</td>
                                                    </tr>
                                                )
                                                :
                                                (
                                                    <tr>
                                                        <td colSpan="6">
                                                            <OnLoading noLabel inline marginAuto />
                                                        </td>
                                                    </tr >
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-12 d-flex justify-content-center'>
                                        {
                                            txHistoryCurrentCount >= limit &&
                                            <div className='row my-5'>
                                                <div className='col-12 d-flex justify-content-center'>
                                                    <a
                                                        onClick={previousTxHistory}
                                                        className={`pagination-pc me-4 user-select-none ${!txHistoryHasPreviousPage ? 'pe-none' : ''}`}
                                                    >
                                                        {translations.formatString(buttonlist.previouspaginationBtn)}
                                                    </a>
                                                    <LineBreak></LineBreak>
                                                    <a onClick={nextTxHistory} className={`pagination-pc ms-4 user-select-none ${!txHistoryHasNextPage ? 'pe-none' : ''}`}>{translations.formatString(buttonlist.nextpaginationBtn)}</a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {/* <BackGradientIcon onClick={() => Navigate(-1)} className="position-absolute" style={{ left: 22, top: 6 }} /> */}
                                <WalletPageTitle className='text-white p-x-16' title={translations.formatString(content.mainTitle)} hasBackIcon />
                                <div style={isPC ? { maxWidth: 450, margin: "0px auto" } : {}}>

                                    <div className="mx-auto w-100" style={isPC ? { maxWidth: 490 } : {}}>
                                        <div className='text-box mt-4'>{id}</div>
                                    </div>
                                    {/* <div className='fs-25 f-condensed text-uppercase text-center m-t-15'>
                                Shard
                            </div> */}
                                    <div className='fs-35 f-condensed text-uppercase text-center m-t-15'>
                                        16,000 <span className="fs-15">PCs</span>
                                    </div>

                                    <div className='d-flex my-5'
                                        style={{ justifyContent: 'space-evenly' }}
                                    >
                                        <div className='d-flex flex-column align-items-center' onClick={() => navigate("/wallet/shards/address")}>
                                            <SendIcon />
                                            <span>Send</span>
                                        </div>
                                        <div className='d-flex flex-column align-items-center' onClick={() => navigate("/wallet/receive")}>
                                            <ReceiveIcon />
                                            <span>Receive</span>
                                        </div>
                                        {/* <div className='d-flex flex-column align-items-center icon-opacity'>
                                <SwapIcon className='wh-25-h-39' />
                                <span>Swap</span>
                            </div> */}
                                    </div>

                                    <div className='divider mt-5 mb-2'></div>

                                    {
                                        loading ? <OnLoading /> :
                                            txHistories?.length > 0 && txHistories.map((item, index) =>

                                                <div key={'tx-' + index} className="card token-card my-3" ref={txHistories.length === index + 1 ? ref : null}>
                                                    <div className="card-body d-flex align-items-center">
                                                        <div className='col-12 py-2'>
                                                            <div className='d-flex flex-row justify-content-between align-items-start position-relative'>
                                                                <div className='d-flex align-items-center'>

                                                                    <div className='d-flex align-items-start'>
                                                                        {
                                                                            wallet_address === item.from ?
                                                                                <SendIcon className="purple-icon" /> :
                                                                                <ReceiveIcon className="purple-icon" />
                                                                        }
                                                                        <div className='d-flex flex-column m-l-15'>
                                                                            <span className='token-name mb-0 f-regular fs-16 primary-color'>

                                                                                {
                                                                                    wallet_address === item.from ?
                                                                                        "Sent" :
                                                                                        "Received"
                                                                                }
                                                                            </span>
                                                                            <span className='m-t-5 fs-12'>
                                                                                {moment(item.timestamp).format("MMM DD, \xa0HH:mm")}
                                                                            </span>
                                                                            <div style={{ overflow: "hidden" }}>
                                                                                <div className="d-flex flex-column" style={{
                                                                                    marginTop: expand && expandIndex === item.tx_id ? 0 : "-100%",
                                                                                    transition: "all 0.4s"
                                                                                }}>
                                                                                    <span className='token-name mb-0 f-regular fs-16 primary-color'>
                                                                                        TX ID
                                                                                    </span>
                                                                                    <span className='m-t-5 fs-12' style={{ width: 250, overflowWrap: "break-word" }}>
                                                                                        {item.tx_hash}
                                                                                    </span>
                                                                                    <span className='token-name mb-0 f-regular fs-16 primary-color'>
                                                                                        from
                                                                                    </span>
                                                                                    <span className='m-t-5 fs-12' style={{ width: 250, overflowWrap: "break-word" }}>
                                                                                        {item.from}
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className='token-price position-absolute' style={{ right: 0 }}>
                                                                    <span className='m-r-15 fs-20'>
                                                                        {/* {item.amount > 0 ? item.amount : 1} */}
                                                                        {formatNumber(item.amount > 0 ? Number(item.amount) : 1)} <span className="fs-14">PCs</span>
                                                                    </span>

                                                                    <ChevronRightIcon className="purple-icon" style={{ rotate: expand && item.tx_id === expandIndex ? "90deg" : "", transition: ".2s ease-in-out" }} onClick={() => {
                                                                        setExpand(!expand)
                                                                        setExpandIndex(item.tx_id)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                    {
                                        paginationLoading && (
                                            <OnLoading
                                                pRelative
                                                noLabel
                                                marginAuto
                                                className={"m-0"}
                                            />
                                        )
                                    }
                                </div>
                            </>
                }
            </AppWrapper>
        </>
    )
}

export default ShardTransaction